import axios from 'axios';

axios.defaults.baseURL = 'https://us-central1-ctips-e397d.cloudfunctions.net/ctips';

export default {
    namespaced: true,
    state: {        
        proveedor: null,
        proveedores: [],  
        proveedorSeleccionado: null,
        editandoProveedor: false,
        cargando: true  
    },
    mutations: {
        SET_PROVEEDORES(state, proveedores){
          state.proveedores = proveedores;
        }, 
        SET_CARGANDO(state, valor){
          state.cargando = valor;
        },
        SET_PROVEEDOR(state, proveedor){
          state.proveedor = proveedor;
        },
        SET_PROVEEDOR_SELECCIONADO(state, proveedor){
          state.proveedorSeleccionado = proveedor;
        },
        SET_EDITANDO_PROVEEDOR(state, valor){
          state.editandoProveedor = valor;
        }, 
    },
    actions: {       

        fetchProveedores({ commit }, nitEmpresaAsociada){
            return new Promise((resolve, reject)=>{
                  axios.post('/traer-proveedores', {
                    nitEmpresaAsociada
                  })
                  .then(response => {
                      commit('SET_PROVEEDORES', response.data.proveedores);
                      commit('SET_CARGANDO', false);
                      resolve('ok');
                  })
                  .catch(error => {
                      reject(error);
                  });
            });          
        },
      
        agregarProveedor({ commit }, proveedor) {
            return new Promise((resolve, reject) => {
              axios.post('/crear-proveedor', proveedor)
                .then(response => {
                   resolve('ok');
                })
                .catch(error => {
                   reject(error);
                });
            });
        },

        asignarProveedorSeleccionado({ commit }, proveedor){
          commit("SET_PROVEEDOR_SELECCIONADO", proveedor); 
        },

        asignarEditandoProveedor({ commit }, valor){
          commit("SET_EDITANDO_PROVEEDOR", valor);
        },

        actualizarProveedor({ commit }, proveedorActualizado) {
          return new Promise((resolve, reject) => {
            axios.put('/actualizar-proveedor', proveedorActualizado)
              .then(response => {
                 resolve('ok');
              })
              .catch(error => {
                 reject(error);
              });
          });
        },
        
        eliminarProveedor({ commit }, proveedor) {
            return new Promise((resolve, reject) => {
              axios.delete('/eliminar-proveedor/' + proveedor.id)
                .then(response => {
                  resolve('ok');
                })
                .catch(error => {
                  reject(error);
                });
            });
        },

    },
    getters: {
        getProveedores(state){
            return state.proveedores;
        } 
    }

}

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { firebaseBase } from "@/store/credenciales";
import { mapState } from "vuex";
import axios from "axios";
library.add(faTimes);

axios.defaults.baseURL = "https://us-central1-ctips-e397d.cloudfunctions.net/ctips";

export default {
  props: {
    show: Boolean,
  },
  created() {
    this.initReCaptcha();
  },
  data() {
    return {
      checked: false,
      phNo: "",
      appVerifier: "",
      otp: "",
      showModal: false,
      vista: 1,
      load_envio_sms: false,
      load_inicio_registro: false,
      user: {
        uid: "",
        token: "",
        telefono: "",
      },
    };
  },
  computed: {
    ...mapState(["logueado", "usuario"]),
    telefonoCelular: {
      get() {
        return this.$store.state.telefonoCelular;
      },
      set(value) {
        this.$store.commit("actualizarTelefonoCelular", value);
      },
    },
  },
  methods: {
    sendOtp() {
      if (!this.checked) {
        alert("Para continuar por favor acepta nuestros Términos y Condiciones y Políticas de Privacidad");
        return;
      }

      if (this.phNo.length !== 10) {
        alert("Debe ingresar un número de teléfono valido");
      } else {
        this.load_envio_sms = true;
        let countryCode = "+57";
        let phoneNumber = countryCode + this.phNo;
        let appVerifier = this.appVerifier;
        let _this = this;

        firebaseBase
          .auth()
          .signInWithPhoneNumber(phoneNumber, appVerifier)
          .then(function (confirmationResult) {
            window.confirmationResult = confirmationResult;
            console.log("Código de verificación enviado");
            _this.load_envio_sms = false;
            _this.vista = 2;
          })
          .catch(function (error) {
            _this.load_envio_sms = false;
            alert("No se ha podido enviar el Código de verificación");
          });
      }
    },

    verifyOtp() {
      if (this.phNo.length !== 10 || this.otp.length !== 6) {
        alert("¡Código de verificación no válido!");
      } 
      else {
        this.load_inicio_registro = true;
        let vm = this;
        let code = this.otp;
        let _this = this;

        window.confirmationResult
          .confirm(code)
          .then(async function (result) {
            
            // console.log(result.user.multiFactor.user.uid + "UID")
            _this.user.uid = result.user.multiFactor.user.uid;
            _this.user.token = result.user.multiFactor.user.accessToken;
            //_this.user.telefono = result.user.multiFactor.user.phoneNumber.slice(3);
            _this.user.telefono = result.user.multiFactor.user.phoneNumber;

            _this.vista = 1;
            _this.phNo = "";
            _this.otp = "";

            _this.load_inicio_registro = false;
            _this.$emit("close");
            _this.$store.commit("inicioSesionExitoso", _this.user);

            // Guardar usuario en firebase
            const usuario = {
              telefono: _this.user.telefono,
            };

            await axios
              .post("/crear-usuario", usuario)
              .then((response) => {
                console.log("Usuario creado");
              })
              .catch((error) => {
                console.error("Error al crear usuario");
              });

            window.location.reload();
          })
          .catch(function (error) {
            alert("¡Código de verificación inválido! Por favor, inténtalo de nuevo.");
            _this.load_inicio_registro = false;
          });
      }
    },

    initReCaptcha() {
      setTimeout(() => {
        let vm = this;
        window.recaptchaVerifier = new firebaseBase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: function (response) {},
            "expired-callback": function () {},
          }
        );
        this.appVerifier = window.recaptchaVerifier;
      }, 1000);
    },
  },
};
</script>

<template>
  <div>
    <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              Iniciar sesión o registrarse
              <div class="close-reg" @click="$emit('close')">
                <font-awesome-icon :icon="['fas', 'times']" />
              </div>
            </div>

            <div class="modal-body">
              <div class="form-group" v-show="vista === 1">
                <label
                  class="login-con-telefono-contenedor-titulo"
                  style="font-size: 13px"
                >
                  Por favor, <strong>ingresa tu número de celular</strong> para
                  <strong
                    ><span>registrarte</span> o para
                    <span>iniciar sesión</span> si ya eres usuario.</strong
                  >
                </label>
                <br />

                <div class="shadow-input">
                  <select class="form-select mt-select select-union" disabled>
                    <option selected disabled value="+57">
                      Colombia (+57)
                    </option>
                  </select>
                  <input
                    type="tel"
                    :disabled="load_envio_sms === true"
                    class="form-control mb-input input-union"
                    v-model="phNo"
                    placeholder="Número de teléfono"
                    onkeypress="return (event.charCode === 8 || event.charCode === 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                  />
                </div>

                <p class="text-left login-con-telefono-contenedor-text-small">
                  Te vamos a enviar un mensaje de texto con un código para
                  confirmar tu número de teléfono. Ingresa el código en el
                  siguiente paso.
                </p>

                <div class="form-group seccion-terminos-condiciones">
                  <div class="filter-tags">
                    <input
                      v-model="checked"
                      id="terminos_condiciones_politicas_de_privacidad_telefono"
                      type="checkbox"
                      name="terminos"
                      :disabled="load_envio_sms === true"
                    />
                    <label
                      for="terminos_condiciones_politicas_de_privacidad_telefono"
                      >Acepto los
                      <a target="_blank">Términos y Condiciones</a> y las
                      <a target="_blank">Políticas de Privacidad</a>.</label
                    >
                  </div>
                </div>

                <a
                  v-if="load_envio_sms === false"
                  class="estoy-interesado-contenedor-btn login-con-telefono-contenedor-btn"
                  @click="sendOtp"
                >
                  Continuar
                </a>
                <a
                  v-if="load_envio_sms === true"
                  class="estoy-interesado-contenedor-btn login-con-telefono-contenedor-btn disabled"
                >
                  Cargando...
                </a>
              </div>

              <div
                class="form-group"
                v-show="vista === 2"
                style="font-size: 13px"
              >
                <div class="custom-form">
                  <label style="margin-bottom: 0.5rem" class="titulo-label"
                    >En los próximos 30 segundos llegará un
                    <strong>código de verificación a su celular</strong>, por
                    favor ingréselo para continuar:</label
                  >
                </div>
                <input
                  type="text"
                  class="form-control mb-control shadow-input"
                  v-model="otp"
                  placeholder="__ __ __ __ __ __"
                  style="margin-bottom: 12px"
                />
                <div class="div-login">
                  <span v-text="`¿${this.phNo} no es tu número de teléfono? `">
                  </span
                  ><a href="#" @click.prevent="vista = 1" class="enlace-directo"
                    >Modificar</a
                  >
                </div>

                <a
                  v-if="load_inicio_registro === false"
                  class="estoy-interesado-contenedor-btn login-con-telefono-contenedor-btn"
                  @click="verifyOtp"
                >
                  Continuar
                </a>
                <a
                  v-if="load_inicio_registro === true"
                  class="estoy-interesado-contenedor-btn login-con-telefono-contenedor-btn disabled"
                >
                  Cargando...
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <!--div para el recaptcha de firebase-->
    <div id="recaptcha-container"></div>
  </div>
</template>

<style scoped>
.disabled {
  opacity: 0.6;
}

.enlace-directo {
  color: #217dbe;
}

.div-login {
  text-align: left;
  display: inline-block;
  font-size: 0.7rem;
  width: 100%;
  color: grey;
}

.close-reg {
  text-align: end;
  font-size: 18px;
  color: grey;
  cursor: pointer;
}
.login-con-telefono-contenedor-btn {
  width: 100%;
  margin-top: 14px !important;
  font-weight: 400 !important;
  cursor: pointer;
}

.estoy-interesado-contenedor-btn {
  background: #f60163;
  padding: 11px 25px;
  font-weight: 700 !important;
  color: #fff;
  border-radius: 50px;
  margin-top: 2em;
  display: inline-block;
  font-size: 0.9rem;
  text-align: center;
  text-decoration: none;
}

.filter-tags label > a {
  color: #484848;
  font-weight: 700;
}

.seccion-terminos-condiciones .filter-tags label {
  font-size: 0.7rem;
  font-weight: 400;
}

.form-group label {
  text-align: left;
  display: inline-block;
}
.filter-tags label {
  color: #484848 !important;
  font-weight: 400 !important;
}
.custom-form .filter-tags label,
.filter-tags label {
  float: left;
  padding: 0 10px;
  position: relative;
  top: 4px;
  color: #888da0;
  font-weight: 600;
  width: auto;
  font-size: 11px;
}

.custom-form .filter-tags input,
.filter-tags input {
  float: left;
  border: 1px solid #ccc;
  cursor: pointer;
  padding: 0;
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 2px;
  color: #fff;
  background: #fff;
  /*-webkit-appearance: none;*/
}

.seccion-terminos-condiciones .filter-tags {
  display: flex;
}
.lost_password a,
.main-register-holder .filter-tags {
  float: left;
  color: #7d93b2;
  font-size: 12px;
  font-weight: 600;
}

.seccion-terminos-condiciones {
  border-top: 1px solid #d4d4d4;
  padding-top: 1em;
}

.seccion-terminos-condiciones {
  margin-top: 1em !important;
  padding-top: 0.9rem !important;
}
.seccion-terminos-condiciones {
  margin-top: 0.8em;
}

.text-left {
  text-align: left;
}

.login-con-telefono-contenedor-text-small {
  font-size: 11px;
  font-weight: 300 !important;
  color: grey;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border: 1px solid #4b4b4b;
  outline: 0;
  //box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.3;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.select-union {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.3;
  color: #212529;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.shadow-input {
  margin-top: 10px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  border-radius: 5px;
}

.form-group label {
  text-align: left;
  display: inline-block;
}

.login-con-telefono-contenedor-titulo span,
.modal_main-login-y-registro-titulo span {
  text-decoration: underline;
}

.login-con-telefono-contenedor-titulo {
  color: #484848 !important;
}
.login-con-telefono-contenedor-titulo strong,
.modal_main-login-y-registro-titulo strong {
  font-weight: 700;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  line-height: 1.3 !important;
}

.modal-container {
  width: 420px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>

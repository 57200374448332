<template>
  <div>
    <Header></Header>
    <div class="container">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <h4 class="mb-3 text-center">Datos empresa</h4>
          <form @submit.prevent="actualizarDatosEmpresa">
            <div class="form-group">
              <label for="nombre">Nombre:</label>
              <input
                type="text"
                class="form-control"
                id="nombre"
                :readonly="!admin && empresa.nit !== ''"
                v-model="empresa.nombre"
                placeholder="Nombre:"
              />
            </div>

            <div class="form-group">
              <label for="direccion">Dirección:</label>
              <input
                type="text"
                class="form-control"
                id="direccion"
                v-model="empresa.direccion"
                placeholder="Dirección:"
              />
            </div>

            <div class="form-group">
              <label for="nit">NIT:</label>
              <input
                type="text"
                class="form-control"
                id="nit"
                :readonly="!admin && empresa.nit !== ''"
                v-model="empresa.nit"
                placeholder="NIT:"
              />
            </div>

            <div class="form-group">
              <label for="telefono">Teléfono:</label>
              <input
                type="text"
                class="form-control"
                id="telefono"
                v-model="empresa.telefono"
                placeholder="Teléfono:"
              />
            </div>

            <button type="submit" class="btn btn-secondary">Guardar</button>
          </form>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import { mapState, mapActions } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      empresa: {
        nombre: "",
        direccion: "",
        nit: "",
        telefono: "",
      },
    };
  },
  created() {
    this.initFirebaseAuth();
  },
  computed: {
    ...mapState(["logueado", "usuario", "admin", "empresaAsignada", "datosUsuario"]),
  },
  methods: {
    ...mapActions(["verificarAdmin", "verificarEmpresaAsignada", "obtenerDatosUsuario"]),

    async initFirebaseAuth() {
      const user = await this.checkUserAuth();
      if (user) {
        this.$store.commit("usuarioActual");
        this.user = user;

        // Llama a la acción para verificar la empresa asignada
        await this.verificarEmpresaAsignada(this.user.phoneNumber);

        // Verificar si el usuario es admin
        await this.verificarAdmin(this.user.phoneNumber);

        this.obtenerDatosUsuario(this.user.phoneNumber);

        // Verificar si la empresa esta asignada
        if (this.empresaAsignada) {
          this.traerDatosEmpresa();
        }

      } else {
        this.$store.commit("eliminarSesion");
      }
    },

    async checkUserAuth() {
      const usuario = localStorage.getItem("usuario");
      if (!usuario) return null;

      return new Promise((resolve) => {
        firebase.auth().onAuthStateChanged((user) => {
          resolve(user);
        });
      });
    },

    async traerDatosEmpresa() {
      const logueado = firebase.auth().currentUser;
      if (logueado) {
        const telefono = logueado.phoneNumber;

        try {
          const response = await axios.post("/traer-datos-empresa", {
            nit: this.empresaAsignada.nit,
          });

          const { nombre, direccion, nit, telefono } = response.data.empresa;

          this.empresa.nombre = nombre || "";
          this.empresa.direccion = direccion || "";
          this.empresa.nit = nit || "";
          this.empresa.telefono = telefono || "";
          
        } catch (error) {
          console.error("Error", error);
        }
      }
    },

    async actualizarDatosEmpresa() {
      const logueado = firebase.auth().currentUser;
      if (logueado) {
        const telefono = logueado.phoneNumber;

        if (!this.validarCampos(this.empresa)) {
          return;
        }

        try {
          await axios.put("/actualizar-datos-empresa", {
            nombre: this.empresa.nombre,
            direccion: this.empresa.direccion,
            nit: this.empresa.nit,
            telefono: this.empresa.telefono,
          });

          this.$toastr.s("Datos actualizados");
          location.reload();
        } catch (error) {
          console.error("Error", error);
        }
      }
    },

    validarCampos(datos) {
      if (Object.values(datos).some((value) => value.trim() === "")) {
        this.$toastr.e("Todos los campos son requeridos");
        return false;
      }

      return true;
    },
  },
};
</script>

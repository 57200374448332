import Vue from "vue";
import Vuex from "vuex";
import { auth, googleProvider, firebaseBase } from "@/store/credenciales";
import { traerUsuarioLocal } from "@/store/helpers/auth";
import axios from "axios";

import firebase from "firebase/compat/app";

// Modulos
import eventos from "./modulos/eventos";
import organizadores from "./modulos/organizadores";
import empresas from "./modulos/empresas";
import cuentasCobros from "./modulos/cuentasCobros";
import proveedores from "./modulos/proveedores";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    admin: false,
    empresaAsignada: null,
    telefonoCelular: "",
    idTokenUsuarioFirebase: "",
    usuario: "",
    datosUsuario: "",
    logueado: false,
    dispositivo: "web",
  },
  modules: {
    eventos,
    organizadores,
    empresas,
    cuentasCobros,
    proveedores,
  },
  mutations: {
    setAdmin(state, esAdmin) {
      state.admin = esAdmin;
    },
    setEmpresaAsignada(state, payload) {
      state.empresaAsignada = payload;
    },
    actualizarTelefonoCelular(state, estado) {
      state.telefonoCelular = estado;
    },
    setDatosUsuario(state, payload) {
      state.datosUsuario = payload;
    },
    actualizarIdTokenUsuarioFirebase(state, payload) {
      state.idTokenUsuarioFirebase = payload;
      localStorage.setItem("idTokenFirebase", payload);
    },
    inicioSesionExitoso(state, payload) {
      state.usuario = payload;
      localStorage.setItem("usuario", JSON.stringify(state.usuario));
      state.logueado = true;
    },
    eliminarSesion(state) {
      localStorage.removeItem("usuario");
      localStorage.removeItem("tipoPrecio");
      state.usuario = null;
      state.logueado = false;
      state.isChangePrice = false;

      if (auth.currentUser) {
        auth.signOut().then(() => {});
      }
    },
    usuarioActual(state) {
      state.usuario = traerUsuarioLocal();
      state.logueado = !!state.usuario;
    },
    cerrarSesionUsuarioPorTokenVencido(state) {
      localStorage.removeItem("usuario");
      state.usuario = null;
      state.logueado = false;

      if (auth.currentUser) {
        auth.signOut().then(() => {});
      }

      window.location.reload();
    },
    actualizarUsuarioLocal(state, payload) {
      state.usuario = payload;
      localStorage.setItem("usuario", JSON.stringify(state.usuario));
    }   
  },
  actions: {

    obtenerDatosUsuario({ commit }, usuario) {
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection("Usuarios")
          .where("telefono", "==", usuario)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {              
              const userData = {
                nombre: querySnapshot.docs[0].data().nombre || null,
                apellidos: querySnapshot.docs[0].data().apellidos || null,
              };
              commit("setDatosUsuario", userData);
              resolve(true);
            } 
            else {
              console.log("El usuario no fue encontrado en la colección Usuarios.");
              resolve(false);
            }
          })
          .catch((error) => {
            console.error("Error al obtener los datos del usuario:", error);
            resolve(false);
          });
      });
    },

    verificarAdmin({ commit }, usuario) {
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection("Usuarios")
          .where("telefono", "==", usuario)
          .where("admin", "==", 1)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              commit("setAdmin", true);
              resolve(true);
            } else {
              commit("setAdmin", false);
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("Error al realizar la consulta:", error);
            reject(error);
          });
      });
    },

    verificarEmpresaAsignada({ commit }, usuario) {
      return new Promise(async (resolve, reject) => {
        try {
          // Primero, obtén la referencia del usuario
          const userQuerySnapshot = await firebase
            .firestore()
            .collection("Usuarios")
            .where("telefono", "==", usuario)
            .get();

          if (!userQuerySnapshot.empty) {
            const userRef = userQuerySnapshot.docs[0].ref;
            const userDoc = await userRef.get();

            if (userDoc.exists) {
              const userData = userDoc.data();
              const nitEmpresaAsignada = userData.empresaAsignada;

              if (nitEmpresaAsignada) {
                // Luego, busca la empresa con el NIT del usuario
                const empresaQuerySnapshot = await firebase
                  .firestore()
                  .collection("Empresas")
                  .where("nit", "==", nitEmpresaAsignada)
                  .get();

                if (!empresaQuerySnapshot.empty) {
                  const empresaDoc = empresaQuerySnapshot.docs[0];
                  const empresaData = {
                    direccion: empresaDoc.data().direccion,
                    nit: empresaDoc.data().nit,
                    nombre: empresaDoc.data().nombre,
                    telefono: empresaDoc.data().telefono,
                  };

                  commit("setEmpresaAsignada", empresaData);
                  resolve(empresaData);
                  //console.log("EmpresaAsignada cargada");
                } else {
                  // La empresa no fue encontrada
                  commit("setEmpresaAsignada", null);
                  resolve(null);
                }
              } else {
                // El usuario no tiene asignada una empresa
                commit("setEmpresaAsignada", null);
                resolve(null);
              }
            } else {
              // Usuario no encontrado
              commit("setEmpresaAsignada", null);
              resolve(null);
            }
          } else {
            // Usuario no encontrado
            commit("setEmpresaAsignada", null);
            resolve(null);
          }
        } catch (error) {
          console.log("Error:", error);
          commit("setEmpresaAsignada", null);
          reject(error);
        }
      });
    },
  },
  getters: {
    traerUsuario(state) {
      return state.usuario;
    },
    estaLogueado(state) {
      return state.logueado;
    },
  },
});

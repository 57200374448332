<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-6 offset-md-3 p-5 text-center">
          <div class="form-group">
            <label for="dato">Consecutivo de Reserva:</label>
            <input class="form-control" type="text" v-model="dato" />
          </div>
          <button
          :disabled="enviando"
          class="btn btn-dark btn-md"
          @click="llamarWebhook()"
          >
          Enviar <span v-if="showCountdown">({{ countdown + ' Segundos' }})</span>
        </button>

      </div>
    </div>
  </div>

  <ConfirmarReserva  :dato="dato" :show="ShowConfirmarReservaModal" @reservaConfirmada="enviarSolicitud" @close="ShowConfirmarReservaModal = false"></ConfirmarReserva>
</div>

</template>

<script>
  import firebase from "firebase/compat/app";

// Modales
  import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

  import axios from "axios";
  import ConfirmarReserva from "../components/modales/ConfirmarReserva";

  export default {

    components:{
      ConfirmarReserva
    },
    data() {
      return {
        dato: "",
        enviando: false,
        respuesta: null,
        ShowConfirmarReservaModal: false,
        showCountdown: false,
        countdown: 60,
        countdownInterval: null
      };
    },

    beforeMount() {
      this.loadFromLocalStorage();
    },

    beforeDestroy() {
      clearInterval(this.countdownInterval);
    },

    methods: {
      startCountdown() {
        this.showCountdown = true;
        this.enviando = true;
        this.countdownInterval = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
            this.saveToLocalStorage();
          } else {
            clearInterval(this.countdownInterval);
            this.enviando = false;
            this.showCountdown = false;
            localStorage.removeItem('countdownTime');
          }
        }, 1000);
      },
      saveToLocalStorage() {
        localStorage.setItem('countdownTime', JSON.stringify(this.countdown));
      },
      loadFromLocalStorage() {
        const storedCountdownTime = localStorage.getItem('countdownTime');
        if (storedCountdownTime && !this.countdownInterval) {
          this.countdown = JSON.parse(storedCountdownTime);
          if (this.countdown > 0) {
            this.startCountdown();
          }
        }
      },
      async llamarWebhook() {

        if (this.dato.trim() === "") {
          this.$toastr.e(
            'El campo "Consecutivo de Reserva" no puede estar vacío.'
            );
          return;
        }

        // Mostrar modal informativo
        this.ShowConfirmarReservaModal = true;  

      },

      async enviarSolicitud(){
        try {        

          this.enviando = true;

          await axios.post(
            "https://us-central1-ctips-e397d.cloudfunctions.net/ctips/webhook",
            {
              dato: this.dato,
            }
          );

          this.countdown = 60;
          this.startCountdown();

          // Cerrar modal informativo
          this.ShowConfirmarReservaModal = false; 
          // Mostrar una alerta de éxito
          this.$toastr.s("¡Consecutivo enviado con exito!");
          this.dato = "";

        } catch (error) {
          console.error("Error al llamar al endpoint:", error);
          this.$toastr.e("Error al enviar");
        } 
      }
    },
  };
</script>

import moment from "moment";
import "moment/locale/es";

export function obtenerFechaActual() {
  const fecha = new Date();
  moment.locale("es");
  return moment(fecha).format("YYYY-MM-DD");
}

export function generarSlug(texto) {
  // Reemplazar caracteres especiales y espacios con guiones
  const slug = texto
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9\s]/g, "_")
    .replace(/\s+/g, "_");

  // Eliminar guiones repetidos
  return slug.replace(/-+/g, "_");
}

<script>
import firebase from "firebase/compat/app";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  props: {
    show: Boolean,
    editar: Boolean,
  },
  computed: {
    ...mapState(["logueado", "usuario", "admin"]),
    ...mapState("proveedores", ["editandoProveedor", "proveedorSeleccionado"]),
  },
  data() {
    return {
      showModal: false,
      nuevoProveedor: {
        nombre: "",
        apellidos: "",
        tipoDocumento: "C.C.",
        numeroDocumento: "",
        correo: "",
        telefono: "",
        declarante: false,
        tengoCuentaBancaria: true,
        nombreBanco: "",
        tipoCuentaBancaria: "",
        numeroCuentaBancaria: "",
        firma: "",
        app: "Cuenta de ahorros",
      },
    };
  },
  async created() {
    let usuario = localStorage.getItem("usuario");

    if (!usuario) {
      this.$store.commit("eliminarSesion");
      return;
    }

    try {
      const user = firebase.auth().currentUser;

      if (user) {
        this.$store.commit("usuarioActual", user);
        this.user = user;

        // Llama a la acción para verificar la empresa asignada
        await this.verificarEmpresaAsignada(this.user.phoneNumber);
      } else {
        this.$store.commit("eliminarSesion");
      }
    } catch (error) {
      console.log("Error al verificar autenticación:", error);
      this.$store.commit("eliminarSesion");
    }
  },
  computed: {
    ...mapState(["logueado", "usuario", "admin", "empresaAsignada"]),
    ...mapState("proveedores", ["editandoProveedor", "proveedorSeleccionado"]),
  },
  methods: {
    ...mapActions(["verificarAdmin", "verificarEmpresaAsignada"]),
    ...mapActions("proveedores", [
      "fetchProveedores",
      "agregarProveedor",
      "actualizarProveedor",
    ]),

    async agregar() {
      if (!this.validarCampos(this.nuevoProveedor)) {
        return;
      }

      try {
        // Agregar Nit empresa
        this.nuevoProveedor.nitEmpresaAsociada = this.empresaAsignada.nit;

        await this.agregarProveedor(this.nuevoProveedor);

        // Limpiar campos
        this.nuevoProveedor.nombre = "";
        this.nuevoProveedor.apellidos = "";
        this.nuevoProveedor.tipoDocumento = "C.C.";
        this.nuevoProveedor.numeroDocumento = "";
        this.nuevoProveedor.correo = "";
        this.nuevoProveedor.telefono = "";
        this.nuevoProveedor.declarante = false;
        this.nuevoProveedor.tengoCuentaBancaria = true;
        this.nuevoProveedor.nombreBanco = "";
        this.nuevoProveedor.tipoCuentaBancaria = "";
        this.nuevoProveedor.numeroCuentaBancaria = "";
        this.nuevoProveedor.firma = "";

        this.fetchProveedores(this.empresaAsignada.nit);
        this.cerrar();

        this.$toastr.s("Proveedor agregado");
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.$toastr.e(error.response.data.mensaje);
        } else {
          this.$toastr.e("Error inesperado");
        }
      }
    },
    async actualizar() {
      if (!this.validarCampos(this.proveedorSeleccionado)) {
        return;
      }

      try {
        await this.actualizarProveedor(this.proveedorSeleccionado);

        this.fetchProveedores(this.empresaAsignada.nit);
        this.cerrar();

        this.$toastr.s("Proveedor actualizado");
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.$toastr.e(error.response.data.mensaje);
        } else {
          this.$toastr.e("Error inesperado");
        }
      }
    },

    toggleCuentaBancaria() {
      if (this.editandoProveedor) {
        this.proveedorSeleccionado.tengoCuentaBancaria =
          !this.proveedorSeleccionado.tengoCuentaBancaria;
      } else {
        this.nuevoProveedor.tengoCuentaBancaria =
          !this.nuevoProveedor.tengoCuentaBancaria;
      }
    },

    handleFirmaChange(event) {
      const file = event.target.files[0];
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        if (this.editandoProveedor) {
          this.proveedorSeleccionado.firma = e.target.result;
        } else {
          this.nuevoProveedor.firma = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },

    validarCampos(datos) {
      if (datos.nombre.trim() === "") {
        this.$toastr.e("El campo Nombre es requerido");
        return false;
      } else if (datos.apellidos.trim() === "") {
        this.$toastr.e("El campo Apellidos es requerido");
        return false;
      } else if (datos.tipoDocumento.trim() === "") {
        this.$toastr.e("El campo Tipo documento es requerido");
        return false;
      } else if (datos.numeroDocumento.trim() === "") {
        this.$toastr.e("El campo Número documento es requerido");
        return false;
      } else if (datos.telefono.trim() === "") {
        this.$toastr.e("El campo Celular es requerido");
        return false;
      } else if (!datos.telefono.startsWith("+57")) {
        this.$toastr.e(
          "El campo de Celular debe comenzar con +57 seguido de 10 dígitos"
        );
        return false;
      }

      if (datos.tengoCuentaBancaria) {
        if (datos.nombreBanco.trim() === "") {
          this.$toastr.e("El campo Nombre del banco es requerido");
          return false;
        } else if (datos.tipoCuentaBancaria.trim() === "") {
          this.$toastr.e("El campo Tipo cuenta bancaria es requerido");
          return false;
        } else if (datos.numeroCuentaBancaria.trim() === "") {
          this.$toastr.e("El campo Numero bancario es requerido");
          return false;
        }
      }

      return true;
    },

    cerrar() {
      this.$emit("close");
    },
  },
};
</script>

<template>
  <div>
    <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <div v-if="editandoProveedor">
                <div v-text="'Editar Proveedor'"></div>
              </div>
              <div v-else>
                <div v-text="'Agregar Proveedor'"></div>
              </div>
              <div class="close-reg" @click="cerrar()">
                <font-awesome-icon :icon="['fas', 'times']" />
              </div>
            </div>
            <div class="modal-body">
              <template v-if="editandoProveedor">
                <form>
                  <h5 class="text-center mb-3">Datos personales</h5>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="nombreCompleto">Nombre:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="nombreCompleto"
                          v-model="proveedorSeleccionado.nombre"
                          placeholder="Ingrese el nombre"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="nombreCompleto">Apellidos:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="nombreCompleto"
                          v-model="proveedorSeleccionado.apellidos"
                          placeholder="Ingrese los apellidos"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="tipoDocumento">Tipo documento:</label>
                    <select
                      v-model="proveedorSeleccionado.tipoDocumento"
                      class="form-control"
                      id="tipoDocumento"
                    >
                      <option value="C.C." selected>
                        Cédula de Ciudadanía
                      </option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="numeroDocumento">Número documento:</label>
                    <input
                      type="number"
                      class="form-control"
                      id="numeroDocumento"
                      v-model="proveedorSeleccionado.numeroDocumento"
                      placeholder="Ingrese el número de documento"
                    />
                  </div>

                  <div class="form-group">
                    <label for="correoElectronico">Correo electrónico:</label>
                    <input
                      type="text"
                      class="form-control"
                      id="correoElectronico"
                      v-model="proveedorSeleccionado.correo"
                      placeholder="Ingrese el correo electrónico"
                    />
                  </div>

                  <div class="form-group">
                    <label for="numeroDocumento">Número de celular:</label>
                    <input
                      type="text"
                      class="form-control"
                      id="numeroDocumento"
                      placeholder="Ingrese el Número de celular:"
                      v-model="proveedorSeleccionado.telefono"
                    />
                    <small>Debe comenzar con +57 seguido de 10 dígitos.</small>
                  </div>

                  <template v-if="proveedorSeleccionado.firma">
                    <div class="form-group">
                      <label>Firma actual:</label>
                      <img
                        :src="proveedorSeleccionado.firma"
                        alt="Firma actual"
                        width="100"
                      />
                    </div>
                  </template>

                  <div class="form-group">
                    <label for="firma">{{
                      proveedorSeleccionado.firma
                        ? "Cargar nueva firma:"
                        : "Cargar firma:"
                    }}</label>
                    <input
                      type="file"
                      class="form-control-file border"
                      id="firma"
                      placeholder="Adjuntar la firma"
                      @change="handleFirmaChange"
                    />
                  </div>

                  <h5 class="text-center mb-3">Datos bancarios</h5>

                  <div class="form-check mb-3">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="tengoCuentaBancaria"
                      :checked="!proveedorSeleccionado.tengoCuentaBancaria"
                      @change="toggleCuentaBancaria"
                    />
                    <label class="form-check-label" for="tengoCuentaBancaria"
                      >No tengo cuenta bancaria</label
                    >
                  </div>

                  <template v-if="proveedorSeleccionado.tengoCuentaBancaria">
                    <div class="form-group">
                      <label for="">Nombre del banco:</label>
                      <input
                        type="text"
                        placeholder="Nombre del banco:"
                        class="form-control"
                        v-model="proveedorSeleccionado.nombreBanco"
                      />
                    </div>

                    <div class="form-group">
                      <label for="">Tipo cuenta bancaria:</label>
                      <select
                        class="form-control"
                        v-model="proveedorSeleccionado.tipoCuentaBancaria"
                      >
                        <option value="" selected>Tipo cuenta bancaria</option>
                        <option value="Cuenta de ahorros">
                          Cuenta de ahorros
                        </option>
                        <option value="Cuenta corriente">
                          Cuenta corriente
                        </option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label>Numero bancario:</label>
                      <input
                        type="text"
                        placeholder="Numero bancario:"
                        class="form-control"
                        v-model="proveedorSeleccionado.numeroCuentaBancaria"
                      />
                    </div>
                  </template>

                  <button
                    type="button"
                    class="btn btn-secondary"
                    v-on:click="actualizar()"
                  >
                    Actualizar
                  </button>
                </form>
              </template>
              <template v-else>
                <form>
                  <h5 class="text-center mb-3">Datos personales</h5>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="nombreCompleto">Nombre:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="nombreCompleto"
                          v-model="nuevoProveedor.nombre"
                          placeholder="Ingrese el nombre"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="nombreCompleto">Apellidos:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="nombreCompleto"
                          v-model="nuevoProveedor.apellidos"
                          placeholder="Ingrese los apellidos"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="tipoDocumento">Tipo documento:</label>
                    <select
                      v-model="nuevoProveedor.tipoDocumento"
                      class="form-control"
                      id="tipoDocumento"
                    >
                      <option value="C.C." selected>
                        Cédula de Ciudadanía
                      </option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="numeroDocumento">Número documento:</label>
                    <input
                      type="number"
                      class="form-control"
                      id="numeroDocumento"
                      v-model="nuevoProveedor.numeroDocumento"
                      placeholder="Ingrese su número de documento"
                    />
                  </div>

                  <div class="form-group">
                    <label for="correoElectronico">Correo electrónico:</label>
                    <input
                      type="text"
                      class="form-control"
                      id="correoElectronico"
                      v-model="nuevoProveedor.correo"
                      placeholder="Ingrese el correo electrónico"
                    />
                  </div>

                  <div class="form-group">
                    <label for="numeroDocumento">Número de celular:</label>
                    <input
                      type="text"
                      class="form-control"
                      id="numeroDocumento"
                      placeholder="Ingrese el Número de celular:"
                      v-model="nuevoProveedor.telefono"
                    />
                    <small>Debe comenzar con +57 seguido de 10 dígitos.</small>
                  </div>

                  <template v-if="nuevoProveedor.firma">
                    <div class="form-group">
                      <label>Firma actual:</label>
                      <img
                        :src="nuevoProveedor.firma"
                        alt="Firma actual"
                        width="100"
                      />
                    </div>
                  </template>

                  <div class="form-group">
                    <label for="firma">{{
                      nuevoProveedor.firma
                        ? "Cargar nueva firma:"
                        : "Cargar firma:"
                    }}</label>
                    <input
                      type="file"
                      class="form-control-file border"
                      id="firma"
                      placeholder="Adjuntar su firma"
                      @change="handleFirmaChange"
                    />
                  </div>

                  <h5 class="text-center mb-3">Datos bancarios</h5>

                  <div class="form-check mb-3">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="tengoCuentaBancaria"
                      :checked="!nuevoProveedor.tengoCuentaBancaria"
                      @change="toggleCuentaBancaria"
                    />
                    <label class="form-check-label" for="tengoCuentaBancaria"
                      >No tengo cuenta bancaria</label
                    >
                  </div>

                  <template v-if="nuevoProveedor.tengoCuentaBancaria">
                    <div class="form-group">
                      <label for="">Nombre del banco:</label>
                      <input
                        type="text"
                        placeholder="Nombre del banco:"
                        class="form-control"
                        v-model="nuevoProveedor.nombreBanco"
                      />
                    </div>

                    <div class="form-group">
                      <label for="">Tipo cuenta bancaria:</label>
                      <select
                        class="form-control"
                        v-model="nuevoProveedor.tipoCuentaBancaria"
                      >
                        <option value="" selected>Tipo cuenta bancaria</option>
                        <option value="Cuenta de ahorros">
                          Cuenta de ahorros
                        </option>
                        <option value="Cuenta corriente">
                          Cuenta corriente
                        </option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label>Numero bancario:</label>
                      <input
                        type="text"
                        placeholder="Numero bancario:"
                        class="form-control"
                        v-model="nuevoProveedor.numeroCuentaBancaria"
                      />
                    </div>
                  </template>

                  <button
                    type="button"
                    class="btn btn-secondary"
                    v-on:click="agregar()"
                  >
                    Agregar
                  </button>
                </form>
              </template>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.modal-container {
  max-height: calc(100vh - 60px);
  overflow-y: auto;
}

.scroll {
  max-height: 500px;
  overflow-y: scroll;
}

.disabled {
  opacity: 0.6;
}

.close-reg {
  text-align: end;
  font-size: 18px;
  color: grey;
  cursor: pointer;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  line-height: 1.3 !important;
}

.modal-container {
  width: 580px;
  margin: 0 auto;
  padding: 10px 10px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header {
  padding: 0.5rem 1rem;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

a.buttons-mobile-section__bg-whatsapp {
  background-color: #25d366;
}

.button-whatsapp-modal-desktop {
  padding: 4px 10px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
}

a.button-whatsapp-modal-desktop:hover {
  transition: 0.3s;
  background-color: #1ea74f;
}

@media (max-width: 400px) {
  .modal-container {
    width: 350px;
  }
}

@media (min-width: 400px) and (max-width: 500px) {
  .modal-container {
    width: 400px;
  }
}

@media (min-width: 500px) and (max-width: 600px) {
  .modal-container {
    width: 500px;
  }
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>

import moment from 'moment';
import 'moment/locale/es'; // Importa la localización en español

// Formatear una fecha
export function formatearFecha(fecha) {
    moment.locale('es');
    return moment(fecha).format("DD/MM/YYYY");
}

// Formatear moneda
export function formatearMoneda(numero) {
  // Primero, convierte el número a una cadena para poder trabajar con ella
  const numeroStr = numero.toString();

  // Separa la parte decimal de la parte entera (si hay una parte decimal)
  const partes = numeroStr.split('.');
  let parteEntera = partes[0];
  const parteDecimal = partes[1] || '';

  // Agrega el separador de miles (punto) a la parte entera cada 3 dígitos
  if (parteEntera.length > 3) {
    parteEntera = parteEntera.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  // Combina la parte entera y la parte decimal formateadas
  const numeroFormateado = parteEntera + (parteDecimal ? ',' + parteDecimal : '');

  return numeroFormateado;
}
<template>
  <div>
    <Header></Header>

    <div class="container">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <h4 class="mb-3 text-center">
            Proveedores ({{ getProveedores.length }})
          </h4>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-12 text-center">
          <button
            type="button"
            v-on:click="abrirModalAgregarProveedor()"
            class="btn btn-link text-dark mr-2"
          >
            <i class="fas fa-plus"></i> Agregar proveedor
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <template v-if="cargando">
            <div class="col-sm-12 text-center">
              <h5>Cargando...</h5>
            </div>
          </template>
          <template v-else>
            <template v-if="getProveedores.length > 0">
              <div class="list-group mb-5">
                <div
                  v-for="(proveedor, index) of getProveedores"
                  :key="index"
                  class="list-group-item text-dark"
                >
                  <div>
                    <p class="card-text mb-0">
                      <strong>Nombre:</strong>
                      {{ proveedor.nombre + " " + proveedor.apellidos }}
                    </p>
                    <p class="card-text mb-0">
                      <strong>Celular:</strong> {{ proveedor.telefono }}
                    </p>
                    <p class="card-text mb-0">
                      <strong>Documento:</strong> C.C.
                      {{ proveedor.numeroDocumento }}
                    </p>
                  </div>
                  <div class="mt-2">
                    <a
                      href="javascript:void(0)"
                      class="d-block-inline text-danger mr-2"
                      v-on:click="abrirModalEditarProveedor(proveedor)"
                      >Editar</a
                    >
                    <a
                      href="javascript:void(0)"
                      class="d-block-inline text-danger"
                      v-on:click="eliminar(proveedor)"
                      >Eliminar</a
                    >
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="col-sm-12 text-center">
                <h5>Sin resultados</h5>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>

    <AgregarProveedor
      :show="showModalProveedor"
      @close="showModalProveedor = false"
    ></AgregarProveedor>

    <Footer></Footer>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import { mapState, mapActions, mapGetters } from "vuex";

// Modales
import AgregarProveedor from "@/components/modales/AgregarProveedor";

export default {
  components: {
    AgregarProveedor,
  },
  data() {
    return {
      showModalProveedor: false,
    };
  },
  async created() {
    let usuario = localStorage.getItem("usuario");

    if (!usuario) {
      this.$store.commit("eliminarSesion");
      return;
    }

    try {
      const user = firebase.auth().currentUser;

      if (user) {
        this.$store.commit("usuarioActual", user);
        this.user = user;

        // Llama a la acción para verificar la empresa asignada
        await this.verificarEmpresaAsignada(this.user.phoneNumber);
        this.obtenerDatosUsuario(this.user.phoneNumber);
        this.fetchProveedores(this.empresaAsignada.nit);

      } else {
        this.$store.commit("eliminarSesion");
      }
    } catch (error) {
      console.log("Error al verificar autenticación:", error);
      this.$store.commit("eliminarSesion");
    }
  },
  computed: {
    ...mapState(["logueado", "usuario", "admin", "empresaAsignada", "datosUsuario"]),
    ...mapState("proveedores", ["cargando"]),
    ...mapGetters("proveedores", ["proveedores", "getProveedores"]),
  },
  methods: {
    ...mapActions(["verificarAdmin", "verificarEmpresaAsignada", "obtenerDatosUsuario"]),
    ...mapActions("proveedores", [
      "fetchProveedores",
      "asignarProveedorSeleccionado",
      "asignarEditandoProveedor",
      "eliminarProveedor",
    ]),

    abrirModalAgregarProveedor() {
      this.showModalProveedor = true;
      this.asignarEditandoProveedor(false);
    },

    abrirModalEditarProveedor(proveedor) {
      this.showModalProveedor = true;
      this.asignarEditandoProveedor(true);
      const proveedorCopia = { ...proveedor };
      this.asignarProveedorSeleccionado(proveedorCopia);
    },

    async verificarSiEsAdmin() {
      let logueado = firebase.auth().currentUser;
      if (logueado) {
        let usuario = firebase.auth().currentUser.phoneNumber;
        await this.verificarAdmin(usuario);
      }
    },

    async eliminar(proveedor) {
      try {
        await this.eliminarProveedor(proveedor);
        this.$toastr.s("Proveedor eliminado");
        this.fetchProveedores(this.empresaAsignada.nit);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style scoped>
.text-underline {
  text-decoration: underline;
}
</style>

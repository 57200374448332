import axios from 'axios';

axios.defaults.baseURL = 'https://us-central1-ctips-e397d.cloudfunctions.net/ctips';

export default {
    namespaced: true,
    state: {
        cuenta: null,
        cuentas: [],
        cuentaSeleccionada: null,
        editandoCuenta: false,
        cargando: true        
    },
    mutations: {
        SET_CUENTAS(state, cuentas){
          state.cuentas = cuentas;
        },  
        SET_CARGANDO(state, valor){
          state.cargando = valor;
        }, 
        SET_CUENTA(state, cuenta){
          state.cuenta = cuenta;
        }, 
        SET_EDITANDO_CUENTA(state, valor){
          state.editandoCuenta = valor;
        }, 
        SET_CUENTA_SELECCIONADA(state, cuenta){
          state.cuentaSeleccionada = cuenta;
        },      
        SET_EDITANDO_CUENTA(state, valor){
          state.editandoCuenta = valor;
        },      
    },
    actions: {       

        fetchCuentas({ rootState, commit }, nitEmpresaAsignada = null){

            let datos = {
              telefono: rootState.usuario.telefono,
              nitEmpresaAsignada: nitEmpresaAsignada
            }
            
            axios.post('/listar-cuentas-cobros', datos)
            .then(response => {
               commit('SET_CUENTAS', response.data.cuentas);
               commit('SET_CARGANDO', false);
            })
            .catch(error => {
               console.error("Error al listar cuentas", error);
            })
            .finally(()=> {
                commit('SET_CARGANDO', false);
            });
        },
   
        asignarCuentaSeleccionada({ commit }, cuenta){
            commit("SET_CUENTA_SELECCIONADA", cuenta); 
        },

        asignarEditandoCuenta({ commit }, valor){
            commit("SET_EDITANDO_CUENTA", valor);
        },
    
        agregarCuenta({ commit }, cuenta) {
            return new Promise((resolve, reject) => {
              axios.post('/crear-cuenta-cobro', cuenta)
                .then(response => {
                   resolve('ok');
                })
                .catch(error => {
                   reject(error);
                });
            });
        },
    
        actualizarCuenta({ commit }, cuentaActualizada) {
            return new Promise((resolve, reject) => {
              axios.put('/actualizar-cuenta-cobro', cuentaActualizada)
                .then(response => {
                   resolve('ok');
                })
                .catch(error => {
                   reject(error);
                });
            });
        },
        
        eliminarCuenta({ commit }, cuenta) {
            return new Promise((resolve, reject) => {
              axios.delete('/eliminar-cuenta-cobro/' + cuenta.id)
                .then(response => {
                  resolve('ok');
                })
                .catch(error => {
                  reject(error);
                });
            });
        },
  
    },
    getters: {
        getCuentas(state){
            return state.cuentas;
        } 
    }

}

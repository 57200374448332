<script>
    import { mapState, mapMutations, mapActions } from "vuex";
  
    export default {
      props: {
        show: Boolean,
        editar: Boolean,
        dato: String
      },
      computed:{  
      },
      data(){
        return {
          showModal: false,
          confirmacion: true,
        }
      },   
      methods :{
        confirmarReserva() {
            this.$emit('reservaConfirmada');
        },   
        cerrar(){
           this.$emit('close');
        }
      }      
    }
    </script>
    
    <template>
      <div>
        <Transition name="modal">
          <div v-if="show" class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-container">
                <div class="modal-header">
                    <div>
                        <h5 v-text="'Consecutivo: ' + dato"></h5> 
                    </div>
                    <div class="close-reg" @click="cerrar()"><font-awesome-icon :icon="['fas', 'times']"/></div>
                </div>    
                <div class="modal-body" style="text-align: justify;">   
                  <div> 
                    <p>Se entiende que, al llevar a cabo esta acción, estás cerrando la reserva y has revisado en Autobit la siguiente información:</p>
                    <p>- El valor de la venta y el cliente, asegurándote de que el cliente esté creado en Alegra.<br>
                    - Las compras y sus respectivos proveedores, verificando que no posean genéricos y que estén registrados como proveedores en Alegra.</p>
                    <p>Es importante destacar que esta acción genera automáticamente documentos en el software contable, por lo que se requiere una revisión detallada tanto de la venta como de las compras.</p>
                    <p>Recibirán un correo de confirmación para la factura del vendedor y un correo adicional por cada compra realizada a una persona natural.</p>
                  </div>

                  <div class="mb-3">                   
                    <label for="confirmacionCheck"><input type="checkbox" v-model="confirmacion" id="confirmacionCheck"> Confirmo haber revisado en Autobits todos los aspectos relacionados con la reserva.</label>
                  </div>

                  <div class="text-center">
                  <button 
                  type="button" 
                  class="btn btn-primary" 
                  @click="confirmarReserva" 
                  :disabled="!confirmacion">
                    Cerrar Reserva
                  </button>
                </div>
                </div>            
              </div>
            </div>
          </div>
        </Transition>
         
    </div>        
    </template>
    
    <style scoped> 

    .modal-container{
      max-height: calc(100vh - 60px);
      overflow-y: auto;
    }  

    .scroll {
      max-height: 500px; 
      overflow-y: scroll;    
    }
 
    .disabled {
      opacity: .6;
    }    
      
    .close-reg{
      text-align: end;
      font-size: 18px;
      color: grey;
      cursor: pointer;
    }           
    
    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: table;
      transition: opacity 0.3s ease;
    }
    
    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
      line-height: 1.3!important;
    }
    
    .modal-container {
      width: 580px;
      margin: 0 auto;
      padding: 10px 10px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
    }
    
    .modal-header{
      padding: 0.5rem 1rem;
    }
    
    .modal-header h3 {
      margin-top: 0;
      color: #42b983;
    }  
    
    a.buttons-mobile-section__bg-whatsapp {
      background-color: #25d366;
    }
    
    .button-whatsapp-modal-desktop {
      padding: 4px 10px;
      display: flex;
      align-items: center;
      border-radius: 25px;
      color: #fff;
      font-size: 1.2rem;
      font-weight: bold;
    }
    
    a.button-whatsapp-modal-desktop:hover {
      transition: .3s;
      background-color: #1ea74f;
    }
    
    @media (max-width: 400px){
      .modal-container {
        width: 350px;
      }
    }
    
    @media (min-width: 400px) and (max-width: 500px){
      .modal-container {
        width: 400px;
      }
    }
    
    @media (min-width: 500px) and (max-width: 600px){
      .modal-container {
        width: 500px;
      }
    }
     
    .modal-enter-from .modal-container,
    .modal-leave-to .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    </style>
<template>
  <div>          
      <div class="pt-4 text-center">
        <template v-if="logueado === true">
            <button type="button" @click="cerrarSesion" class="btn btn-link">Cerrar Sesión</button>
        </template>     
      </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  components: {
  },
  props:{
    theme:{
      type: String,
      default: 'default'
    }
  },
  data(){
    return{
      showModal: false
    }
  },
  computed: {
    ...mapState(['logueado', 'usuario', 'admin']),
  },
  methods :{
    cerrarSesion(){
       this.$store.commit('cerrarSesionUsuarioPorTokenVencido');
    }
  },

}
</script>

<style>
</style>
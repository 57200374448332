import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';   // for cloud firestore

const firebaseConfig = {
  apiKey: "AIzaSyCE8bWvgZ6MLXcIFfjuPyV1tMtJBG7OARw",
  authDomain: "ctips-e397d.firebaseapp.com",
  projectId: "ctips-e397d",
  storageBucket: "ctips-e397d.appspot.com",
  messagingSenderId: "245782902394",
  appId: "1:245782902394:web:e4869abc192dfb0ef06dc3",
  measurementId: "G-FN28MQPCW3"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider()
export const firebaseBase = firebase;
export const Firestore = firebaseBase.firestore();
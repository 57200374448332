<template>
  <div>
    <Header></Header>    
    <div class="container text-center">
      <div class="py-3">---</div>
      <div>
        <router-link to="/cuentasdecobro/empresa/datosempresa" class="btn btn-link text-dark">Datos Empresa</router-link>
      </div>
      <div>
        <router-link to="/cuentasdecobro/empresa/proveedores" class="btn btn-link text-dark">Proveedores</router-link>
      </div>
      <div>
        <router-link to="/cuentasdecobro/empresa/cuentas" class="btn btn-link text-dark">Cuentas de Cobros</router-link>
      </div>
      <div class="py-3">---</div>
      <div v-if="empresaAsignada">
        <router-link to="/cuentasdecobro" class="btn btn-link text-dark text-underline">Cambiar a Perfil Personal</router-link>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import { mapState, mapActions } from "vuex";

export default {
  async created() {

    let usuario = localStorage.getItem("usuario");

    if (!usuario) {
      this.$store.commit("eliminarSesion");
      return;
    }

    try {
      const user = firebase.auth().currentUser;

      if (user) {
        this.$store.commit("usuarioActual", user);
        this.user = user;

        // Llama a la acción para verificar la empresa asignada
        await this.verificarEmpresaAsignada(this.user.phoneNumber);

        this.obtenerDatosUsuario(this.user.phoneNumber);
      } 
      else {
        this.$store.commit("eliminarSesion");
      }
    } 
    catch (error) {
      console.log("Error al verificar autenticación:", error);
      this.$store.commit("eliminarSesion");
    }
    
  },
  computed: {
    ...mapState(["logueado", "usuario", "admin", "empresaAsignada", "datosUsuario"]),
  },
  methods: {
    ...mapActions(["verificarAdmin", "verificarEmpresaAsignada", "obtenerDatosUsuario"]),
  },
};
</script>

<style scoped>
.text-underline{
  text-decoration: underline;
}
</style>
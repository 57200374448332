import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import InstantSearch from 'vue-instantsearch';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import { faPlus, faMinus, faEdit, faTrashAlt, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";
import VueAnalytics from 'vue-analytics';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

import '@fortawesome/fontawesome-free/css/all.css';

import * as Swal from 'sweetalert2'

import $ from 'jquery'
window.$ = $

Vue.component('Header', require('./components/Header').default)
Vue.component('Menu_eventos', require('./components/navbars/Menu_eventos').default)
Vue.component('Footer', require('./components/Footer').default)
Vue.component('Modal_registro', require('./components/Modal_registro').default)
Vue.component('font-awesome-icon', FontAwesomeIcon)

library.add(faPlus, faMinus, faEdit, faTrashAlt, faLongArrowAltLeft, faWhatsapp)

// Vue.use(VueAnalytics, {
//   id: '3642146309',
//   router
// });

Vue.use(InstantSearch);

import VueToastr from "vue-toastr";

// use plugin
Vue.use(VueToastr, {
  /* opciones de configuración */
});

Vue.config.productionTip = false

let app = null

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})

// new Vue({
//   router,
//   store,
//   render: function (h) { return h(App) }
// }).$mount('#app')

// Persona
import Home from "@/views/CuentasDeCobro/Persona/Home";
import MiCuenta from "@/views/CuentasDeCobro/Persona/MiCuenta";
import Empresas from "@/views/CuentasDeCobro/Persona/Empresas";
import CuentasDeCobros from "@/views/CuentasDeCobro/Persona/ListarCuentas";

// Empresa
import HomeEmpresa from "@/views/CuentasDeCobro/Empresa/Home";
import DatosEmpresa from "@/views/CuentasDeCobro/Empresa/DatosEmpresa";
import ProveedoresEmpresa from "@/views/CuentasDeCobro/Empresa/Proveedores";
import CuentasDeCobrosEmpresa from "@/views/CuentasDeCobro/Empresa/ListarCuentas";

const cuentasDeCobroRoutes = [
  {
    path: "/cuentasdecobro",
    component: Home,
    meta: {
      title: "Cuentas de cobro",
      role: "persona",
      esHome: true,
    },
  },
  {
    path: "/cuentasdecobro/cuentas",
    component: CuentasDeCobros,
    meta: {
      title: "Cuentas de cobro",
      role: "persona",
      requiresAuth: true,
      authRedirect: "/cuentasdecobro",
    },
  },
  {
    path: "/cuentasdecobro/misdatos",
    component: MiCuenta,
    meta: {
      title: "Cuentas de cobro - Mis datos",
      role: "persona",
      requiresAuth: true,
      authRedirect: "/cuentasdecobro",
    },
  },
  {
    path: "/cuentasdecobro/clientes",
    component: Empresas,
    meta: {
      title: "Cuentas de cobro - Clientes",
      role: "persona",
      requiresAuth: true,
      authRedirect: "/cuentasdecobro",
    },
  },

  // Rutas Empresa

  {
    path: "/cuentasdecobro/empresa",
    component: HomeEmpresa,
    meta: {
      title: "Cuentas de cobro - Empresas - Home",
      role: "empresa",
      esHome: true,
      requiresAuth: true,
      authRedirect: "/cuentasdecobro",
    },
  },
  {
    path: "/cuentasdecobro/empresa/datosempresa",
    component: DatosEmpresa,
    meta: {
      title: "Cuentas de cobro - Empresas - Datos",
      role: "empresa",
      requiresAuth: true,
      authRedirect: "/cuentasdecobro/empresa",
    },
  },
  {
    path: "/cuentasdecobro/empresa/proveedores",
    component: ProveedoresEmpresa,
    meta: {
      title: "Cuentas de cobro - Empresas - Proveedores",
      role: "empresa",
      requiresAuth: true,
      authRedirect: "/cuentasdecobro/empresa",
    },
  },
  {
    path: "/cuentasdecobro/empresa/cuentas",
    component: CuentasDeCobrosEmpresa,
    meta: {
      title: "Cuentas de cobro - Empresas - Cuentas",
      role: "empresa",
      requiresAuth: true,
      authRedirect: "/cuentasdecobro/empresa",
    },
  },
];

export default cuentasDeCobroRoutes;

<template>
  <div>
    <Header></Header>
    <div class="container text-center">
      <div class="py-3">---</div>
      <div>
        <router-link
          to="/cuentasdecobro/misdatos"
          class="btn btn-link"
          :class="{ 'text-dark': logueado, 'disabled-link': !logueado }"
          >Mis datos</router-link
        >
      </div>
      <div>
        <router-link
          to="/cuentasdecobro/clientes"
          class="btn btn-link"
          :class="{ 'text-dark': logueado, 'disabled-link': !logueado }"
          >Clientes</router-link
        >
      </div>
      <div>
        <router-link
          to="/cuentasdecobro/cuentas"
          class="btn btn-link"
          :class="{ 'text-dark': logueado, 'disabled-link': !logueado }"
          >Cuentas de Cobros</router-link
        >
      </div>
      <div class="py-3">---</div>
      <template v-if="logueado">
        <div v-if="empresaAsignada">
          <router-link
            to="/cuentasdecobro/empresa"
            class="btn btn-link text-dark text-underline"
            >Cambiar a Perfil Empresa</router-link
          >
        </div>
      </template>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import { mapState, mapActions } from "vuex";

export default {
  async created() {
    let usuario = localStorage.getItem("usuario");

    if (!usuario) {
      this.$store.commit("eliminarSesion");
      return;
    }

    try {
      const user = firebase.auth().currentUser;

      if (user) {
        this.$store.commit("usuarioActual", user);
        this.user = user;       

        // Verificar si es admin
        await this.verificarAdmin(this.user.phoneNumber);
        // Llama a la acción para verificar la empresa asignada
        await this.verificarEmpresaAsignada(this.user.phoneNumber);     

        this.obtenerDatosUsuario(this.user.phoneNumber);
      } 
      else {
        this.$store.commit("eliminarSesion");
      }
    } 
    catch (error) {
      console.log("Error al verificar autenticación:", error);
      this.$store.commit("eliminarSesion");
    }
  },
  computed: {
    ...mapState(["logueado", "usuario", "admin", "empresaAsignada", "datosUsuario"]),
  },
  methods: {
    ...mapActions(["verificarAdmin", "verificarEmpresaAsignada", "obtenerDatosUsuario"]),
  },
};
</script>

<style scoped>
.text-underline {
  text-decoration: underline;
}

.disabled-link {
  pointer-events: none; /* Deshabilita los eventos de puntero en el enlace */
  color: gray; /* Cambia el color del texto para indicar que está deshabilitado */
  text-decoration: none; /* Elimina la subrayado para que parezca deshabilitado */
  cursor: not-allowed; /* Cambia el cursor al símbolo de "no permitido" */
}
</style>

<template>
  <div>
    <Header></Header>
    <div class="container">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <h4 class="mb-3 text-center">Mis datos</h4>
          <form>
            <h5 class="text-center mb-3">Datos personales</h5>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="nombreCompleto">Nombre:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nombreCompleto"
                    v-model="datosUsuario.nombre"
                    placeholder="Ingrese su nombre"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="nombreCompleto">Apellidos:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nombreCompleto"
                    v-model="datosUsuario.apellidos"
                    placeholder="Ingrese sus apellidos"
                  />
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="tipoDocumento">Tipo documento:</label>
              <select
                v-model="datosUsuario.tipoDocumento"
                class="form-control"
                id="tipoDocumento"
              >
                <option value="C.C." selected>Cédula de Ciudadanía</option>
              </select>
            </div>

            <div class="form-group">
              <label for="numeroDocumento">Número documento:</label>
              <input
                type="number"
                class="form-control"
                id="numeroDocumento"
                v-model="datosUsuario.numeroDocumento"
                placeholder="Ingrese su número de documento"
              />
            </div>

            <div class="form-group">
              <label for="correoElectronico">Correo electrónico:</label>
              <input
                type="text"
                class="form-control"
                id="correoElectronico"
                v-model="datosUsuario.correo"
                placeholder="Ingrese su correo electrónico"
              />
            </div>

            <div class="form-group">
              <label for="telefono">Celular:</label>
              <input
                type="text"
                class="form-control"
                id="telefono"
                readonly
                :value="usuario.telefono"
              />
            </div>

            <template v-if="datosUsuario.firma">
              <div class="form-group">
                <label>Firma actual:</label>
                <img :src="datosUsuario.firma" alt="Firma actual" width="100" />
              </div>
            </template>

            <div class="form-group">
              <label for="firma">{{
                datosUsuario.firma ? "Cargar nueva firma:" : "Cargar firma:"
              }}</label>
              <input
                type="file"
                class="form-control-file border"
                id="firma"
                placeholder="Adjuntar su firma"
                @change="handleFirmaChange"
              />
            </div>

            <h5 class="text-center mb-3">Datos bancarios</h5>

            <div class="form-check mb-3">
              <input
                type="checkbox"
                class="form-check-input"
                id="tengoCuentaBancaria"
                :checked="!datosUsuario.tengoCuentaBancaria"
                @change="toggleCuentaBancaria"
              />
              <label class="form-check-label" for="tengoCuentaBancaria"
                >No tengo cuenta bancaria</label
              >
            </div>

            <template v-if="datosUsuario.tengoCuentaBancaria">
              <div class="form-group">
                <label for="">Nombre del banco:</label>
                <input
                  type="text"
                  placeholder="Nombre del banco:"
                  class="form-control"
                  v-model="datosUsuario.nombreBanco"
                />
              </div>

              <div class="form-group">
                <label for="">Tipo cuenta bancaria:</label>
                <select
                  class="form-control"
                  v-model="datosUsuario.tipoCuentaBancaria"
                >
                  <option value="" selected>Tipo cuenta bancaria</option>
                  <option value="Cuenta de ahorros">Cuenta de ahorros</option>
                  <option value="Cuenta corriente">Cuenta corriente</option>
                </select>
              </div>

              <div class="form-group">
                <label>Numero bancario:</label>
                <input
                  type="text"
                  placeholder="Numero bancario:"
                  class="form-control"
                  v-model="datosUsuario.numeroCuentaBancaria"
                />
              </div>
            </template>
            <button
              type="button"
              class="btn btn-secondary"
              v-on:click="actualizarUsuario()"
            >
              Guardar
            </button>
          </form>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import firebase from "firebase/compat/app";
import { mapState, mapActions } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      datosUsuario: {
        nombre: "",
        apellidos: "",
        tipoDocumento: "",
        numeroDocumento: "",
        correo: "",
        declarante: false,
        tengoCuentaBancaria: true,
        nombreBanco: "",
        tipoCuentaBancaria: "",
        numeroCuentaBancaria: "",
        firma: "",
      },
    };
  },
  async created() {
    let usuario = localStorage.getItem("usuario");

    if (!usuario) {
      this.$store.commit("eliminarSesion");
      return;
    }

    try {
      const user = firebase.auth().currentUser;

      if (user) {
        this.$store.commit("usuarioActual", user);
        this.user = user;

        this.verificarSiEsAdmin();
        this.consultarUsuario();

        this.obtenerDatosUsuario(this.user.phoneNumber);
      } else {
        this.$store.commit("eliminarSesion");
      }
    } catch (error) {
      console.log("Error al verificar autenticación:", error);
      this.$store.commit("eliminarSesion");
    }
  },
  computed: {
    ...mapState(["logueado", "usuario", "admin"]),
  },
  methods: {
    ...mapActions(["verificarAdmin", "obtenerDatosUsuario"]),

    async verificarSiEsAdmin() {
      let logueado = firebase.auth().currentUser;
      if (logueado) {
        let usuario = firebase.auth().currentUser.phoneNumber;
        await this.verificarAdmin(usuario);
      }
    },

    async consultarUsuario() {
      let logueado = firebase.auth().currentUser;

      if (logueado) {
        let telefono = firebase.auth().currentUser.phoneNumber;

        await axios
          .post("/consultar-usuario", {
            telefono: telefono,
            app: "Cuentas de cobro",
          })
          .then((res) => {
            const {
              nombre,
              apellidos,
              tipoDocumento,
              numeroDocumento,
              correo,
              declarante,
              nombreBanco,
              tipoCuentaBancaria,
              numeroCuentaBancaria,
              tengoCuentaBancaria,
              firma,
            } = res.data.usuario;

            this.datosUsuario.nombre = nombre || "";
            this.datosUsuario.apellidos = apellidos || "";
            this.datosUsuario.tipoDocumento = tipoDocumento || "";
            this.datosUsuario.numeroDocumento = numeroDocumento || "";
            this.datosUsuario.correo = correo || "";
            this.datosUsuario.tengoCuentaBancaria = tengoCuentaBancaria || false;
            this.datosUsuario.nombreBanco = nombreBanco || "";
            this.datosUsuario.tipoCuentaBancaria = tipoCuentaBancaria || "";
            this.datosUsuario.numeroCuentaBancaria = numeroCuentaBancaria || "";
            this.datosUsuario.declarante = declarante || false;
            this.datosUsuario.firma = firma || "";
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    async actualizarUsuario() {
      let logueado = firebase.auth().currentUser;

      if (logueado) {
        let telefono = firebase.auth().currentUser.phoneNumber;

        if (!this.validarCampos(this.datosUsuario)) {
          return;
        }

        await axios
          .post("/actualizar-usuario", {
            telefono: telefono,
            nombre: this.datosUsuario.nombre,
            apellidos: this.datosUsuario.apellidos,
            tipoDocumento: this.datosUsuario.tipoDocumento,
            numeroDocumento: this.datosUsuario.numeroDocumento,
            correo: this.datosUsuario.correo,
            tengoCuentaBancaria: this.datosUsuario.tengoCuentaBancaria,
            nombreBanco: this.datosUsuario.nombreBanco,
            tipoCuentaBancaria: this.datosUsuario.tipoCuentaBancaria,
            numeroCuentaBancaria: this.datosUsuario.numeroCuentaBancaria,
            declarante: this.datosUsuario.declarante,
            firma: this.datosUsuario.firma,
            app: "Cuentas de cobro",
          })
          .then((res) => {
            this.$toastr.s("Datos actualizados");
            location.reload();
          })
          .catch((error) => {
            console.log("Error", error);
          });
      }
    },

    toggleCuentaBancaria() {
      this.datosUsuario.tengoCuentaBancaria =
        !this.datosUsuario.tengoCuentaBancaria;
    },

    handleFirmaChange(event) {
      const file = event.target.files[0];

      if (!file) {
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        this.datosUsuario.firma = e.target.result;
        console.log(this.datosUsuario.firma);
      };

      reader.readAsDataURL(file);
    },

    validarCampos(datos) {
      if (datos.nombre.trim() === "") {
        this.$toastr.e("El campo Nombre es requerido");
        return false;
      } else if (datos.apellidos.trim() === "") {
        this.$toastr.e("El campo Apellidos es requerido");
        return false;
      } else if (datos.tipoDocumento.trim() === "") {
        this.$toastr.e("El campo Tipo documento es requerido");
        return false;
      } else if (datos.numeroDocumento.trim() === "") {
        this.$toastr.e("El campo Número documento es requerido");
        return false;
      }

      if (datos.tengoCuentaBancaria) {
        if (datos.nombreBanco.trim() === "") {
          this.$toastr.e("El campo Nombre del banco es requerido");
          return false;
        } else if (datos.tipoCuentaBancaria.trim() === "") {
          this.$toastr.e("El campo Tipo cuenta bancaria es requerido");
          return false;
        } else if (datos.numeroCuentaBancaria.trim() === "") {
          this.$toastr.e("El campo Numero bancario es requerido");
          return false;
        }
      }

      return true;
    },
  },
};
</script>

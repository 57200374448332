<template>
  <div>
    <Header></Header>

    <div class="container-fluid">
      <div class="row mb-3">
        <div class="col-sm-12 text-center">
          <button
            type="button"
            :disabled="!logueado || !organizador"
            v-on:click="abrirModalAgregarEvento('Evento')"
            class="btn btn-link text-dark mr-2"
          >
            <i class="fas fa-plus"></i> Evento
          </button>
        </div>
      </div>

      <div v-if="logueado" class="row mb-3">
        <div class="col-12 text-center">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="notificarCheckbox"
              @change="actualizarEstadoNotificacion()"
              v-model="notificacion"
            />
            <label class="form-check-label" for="notificarCheckbox">
              Notificarme de próximos eventos
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <template v-if="cargando">
          <div class="col-sm-12 text-center">
            <h5>Cargando...</h5>
          </div>
        </template>
        <template v-else>
          <template v-if="eventos.length > 0">
            <div
              class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3"
              v-for="(evento, index) of eventos"
              :key="index"
            >
              <div class="card border-dark">
                <template v-if="evento.portada">
                  <img
                    :src="evento.portada"
                    class="card-img-top"
                    alt="Portada"
                    style="height: 180px; object-fit: cover"
                  />
                </template>
                <div class="card-body p-3">
                  <h5 class="card-title">{{ evento.nombre }}</h5>
                  <p class="small card-text mb-0">
                    <strong>Tipo de evento:</strong> {{ evento.categoria }}
                  </p>
                  <p class="small card-text mb-0">
                    <strong>Fecha:</strong> {{ evento.fecha | formatearFecha }}
                  </p>
                  <p class="small card-text mb-0">
                    <strong>Hora:</strong> {{ evento.fecha | formatearHora }}
                  </p>
                  <p class="small card-text mb-0">
                    <strong>Lugar:</strong> {{ evento.lugar }}
                  </p>
                  <p class="small card-text mb-0">
                    <strong>Dirección:</strong> {{ evento.direccion }}
                  </p>
                  <p class="small card-text mb-0">
                    <strong>Tipo publico:</strong> {{ evento.tipoPublico }}
                  </p>
                  <p class="small card-text mb-0">
                    <strong>Precio:</strong> {{ evento.precio }}
                  </p>
                  <p class="small card-text mb-0">
                    <strong>Instagram:</strong>
                    <a
                      :href="'https://instagram.com/' + evento.instagram"
                      target="_blank"
                      >{{ evento.instagram }}</a
                    >
                  </p>
                  <p class="small card-text mb-0">
                    <strong>Organizador:</strong> {{ evento.organizador }}
                  </p>
                  <p class="small card-text">
                    <strong>Descripcion:</strong> {{ evento.descripcion }}
                  </p>

                  <div
                    v-if="
                      admin || (logueado && usuario.telefono === evento.usuario)
                    "
                  >
                    <a
                      href="#"
                      v-on:click="abrirModalEditarEvento(evento)"
                      class="card-link"
                      >Editar</a
                    >
                    <a href="#" v-on:click="eliminar(evento)" class="card-link"
                      >Eliminar</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="col-sm-12 text-center">
              <h5>Sin resultados</h5>
            </div>
          </template>
        </template>
      </div>
    </div>

    <Footer></Footer>
    <AgregarEvento
      :show="showModalEvento"
      @close="showModalEvento = false"
    ></AgregarEvento>
  </div>
</template>
<script>
import moment from "moment";
import firebase from "firebase/compat/app";
// Modales
import AgregarEvento from "../../components/modales/AgregarEvento";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

import axios from "axios";

export default {
  components: {
    AgregarEvento,
  },
  data() {
    return {
      user: "",
      showModalEvento: false,
      filtroTipo: "",
      notificacion: false,
    };
  },

  async created() {

    let usuario = localStorage.getItem("usuario");

    if (!usuario) {
      this.$store.commit("eliminarSesion");
      return;
    }

    try {
      const user = firebase.auth().currentUser;

      if (user) {
        this.$store.commit("usuarioActual", user);
        this.user = user;

        this.obtenerDatosUsuario(this.user.phoneNumber);
        this.verificarSiEsAdmin();
        this.verificarSiEsOrganizador();   
        this.consultarEstadoNotificacion();        

      } 
      else {
        this.$store.commit("eliminarSesion");
      }

    } catch (error) {
      console.log("Error al verificar autenticación:", error);
      this.$store.commit("eliminarSesion");
    }
  },

  mounted(){
      this.fetchEventos();
      this.fetchCategoriasEventos();
  },

  computed: {
    ...mapState(["logueado", "usuario", "admin", "datosUsuario"]),
    ...mapState("eventos", ["cargando","organizador","eventos", "editandoEvento","tipoEvento", "eventoSeleccionado"]),
    ...mapGetters("eventos", ["getEventos"]),
    eventos() {
      return this.getEventos;
    },
  },
  filters: {
    formatearFecha(firebaseTime) {
      moment.locale("es");
      const date = new Date(firebaseTime._seconds * 1000);
      const formattedDate = moment(date).format("DD [de] MMMM [de] YYYY");
      return formattedDate;
    },
    formatearHora(firebaseTime) {
      moment.locale("es");
      const date = new Date(firebaseTime._seconds * 1000);
      const formattedDate = moment(date).format("hh:mm:ss A");
      return formattedDate;
    },
    formatearFechaHora(firebaseTime) {
      const date = new Date(firebaseTime._seconds * 1000);
      const formattedDate = moment(date).format("YYYY-MM-DDTHH:mm:ss");
      return formattedDate;
    },
  },
  methods: {
    ...mapActions(["verificarAdmin", "obtenerDatosUsuario"]),
    ...mapActions("eventos", [
      "verificarOrganizador",
      "fetchCategoriasEventos",
      "fetchEventos",
      "fetchEventosPorTipo",
      "asignarEventoSeleccionado",
      "asignarTipoEvento",
      "asignarEditandoEvento",
      "eliminarEvento",
    ]),

    async verificarSiEsAdmin() {
      let logueado = firebase.auth().currentUser;
      if (logueado) {
        let usuario = firebase.auth().currentUser.phoneNumber;
        await this.verificarAdmin(usuario);
      }
    },

    async consultarEstadoNotificacion() {
      let logueado = firebase.auth().currentUser;

      if (logueado) {
        let telefono = firebase.auth().currentUser.phoneNumber;

        await axios
          .post("/consultar-estado-notificacion-usuario", {
            telefono: telefono,
          })
          .then((res) => {
            this.notificacion = res.data.notificacion;
          })
          .catch((error) => {
            console.log("Error", error);
          });
      }
    },

    async actualizarEstadoNotificacion() {
      let logueado = firebase.auth().currentUser;

      if (logueado) {
        let telefono = firebase.auth().currentUser.phoneNumber;

        await axios
          .post("/actualizar-estado-notificacion-usuario", {
            telefono: telefono,
          })
          .then((res) => {
            this.$toastr.s("Datos actualizados");
          })
          .catch((error) => {
            console.log("Error", error);
          });
      }
    },

    async verificarSiEsOrganizador() {
      let logueado = firebase.auth().currentUser;
      if (logueado) {
        let usuario = firebase.auth().currentUser.phoneNumber;
        await this.verificarOrganizador(usuario);
      }
    },

    async eliminar(evento) {
      try {
        await this.eliminarEvento(evento);
        this.fetchEventos();
      } catch (error) {
        console.error(error);
      }
    },

    abrirModalAgregarEvento(tipo) {
      this.asignarTipoEvento(tipo);
      this.asignarEditandoEvento(false);
      this.showModalEvento = true;
    },

    abrirModalEditarEvento(evento) {
      this.showModalEvento = true;
      this.asignarEditandoEvento(true);
      const eventoCopia = { ...evento };
      eventoCopia.fecha = this.$options.filters.formatearFechaHora(
        evento.fecha
      );
      this.asignarEventoSeleccionado(eventoCopia);
    },
  },
};
</script>

<style>
.btn-editar,
.btn-eliminar {
  cursor: pointer;
}

.main {
  width: 100%;
  max-width: 790px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
.flotante-cotizacion {
  background: rgba(0, 0, 0, 0.6);
  max-width: 474px;
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 9px;
  border-radius: 34px;
  border: 1px solid #c4c8d8;
  box-shadow: 0 2px 5px 0 #e3e5ec;
}
.nueva-cotizacion {
  max-width: 474px;
  margin: 0 auto;
}
.flotante-cotizacion * {
  color: white;
}
.flotante-cotizacion > div {
  display: inline-block;
  vertical-align: middle;
  width: 33.33%;
}
.flotante-cotizacion .titulo {
  font-weight: bold;
}
.text-link {
  text-decoration: underline;
  cursor: pointer;
}
.nueva-cotizacion div {
  display: inline-block;
  width: 50%;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.caja-image-producto {
  /* background-image: url("https://kyrovet.com/wp-content/uploads/2021/09/24.-COMPLEMIL-500.jpg"); */
  background-size: contain;
  background-repeat: no-repeat;
}
.divBusqueda {
  border-style: solid;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.MyCustomMenuSelect {
  /*max-width:42%;*/
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.MyCustomMenuSelectOption {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.box-select-pc {
  /* display: none; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-select-mobile {
  display: none;
  /* display:flex; */
  justify-content: center;
  align-items: center;
}

a.buttons-mobile-section__bg-whatsapp {
  background-color: #25d366;
}

.button-whatsapp-desktop {
  padding: 4px 10px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
}

a.button-whatsapp-desktop:hover {
  transition: 0.3s;
  background-color: #1ea74f;
}

.button-whatsapp-home {
  position: fixed;
  right: 0;
  z-index: 30;
  bottom: 15px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}

.btn-agregar-disminuir-producto {
  background-color: white;
  border: 0;
  margin: 3px 5px;
  cursor: pointer;
}

.caja-cantidad-producto {
  background-color: white;
  /*width: 10rem;*/
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  border-radius: 20px;
  height: 10px;
}

.crear-orden-btn {
  font-size: 14px;
  font-family: "Helvetica", sans-serif;
  display: inline-block;
  background: rgba(0, 0, 0, 0.6);
  padding: 0 15px;
  line-height: 32px;
  color: #fff;
  border-radius: 16px;
  margin-right: 0;
  margin-bottom: 10px;
  cursor: pointer;
}

.crear-orden-contenedor-btn {
  width: 70%;
  margin-top: 14px !important;
  font-weight: 400 !important;
  cursor: pointer;
}

.s006 {
  margin-top: 14rem;
  padding-top: 0px;
}

.margen-top-s006-logueado {
  margin-top: 0;
}

@media (min-width: 640px) and (max-width: 1400px) {
  .s006 {
    margin-top: 11rem;
  }
  .margen-top-s006-logueado {
    margin-top: 0;
  }

  .inner-form .input-field .btn-search {
    top: 13px;
  }
}

@media (max-width: 640px) {
  .s006 {
    margin-top: 11rem;
  }
  .margen-top-s006-logueado {
    margin-top: 0;
  }
  .inner-form .input-field .btn-search {
    top: 13px;
  }
  .box-select-pc {
    display: none;
  }

  .box-select-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.btn-search {
  height: 57% !important;
}
/**/
.MySearchBox {
  width: 100%;
  height: 100%;
}

.pagination-personalizada {
  margin: 2rem auto;
  text-align: center;
}

.ais-Hits-item {
  border-radius: 5px;
  width: 100%;
  margin-left: 0;
  margin-top: 10px;
  text-align: left;
  background-color: #fbfbfb;
  padding: 0.4rem;
}
.s131 form .inner-form .input-field.first-wrap input {
  border-right: 1px solid #e5e5e5;
}
</style>

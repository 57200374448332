import Vue from "vue";
import VueRouter from "vue-router";

// import HomeView from "../views/Eventos/HomeView.vue";
// import Organizadores from "../views/Eventos/Organizadores.vue";
// import CuentasDeCobros from "../views/CuentasDeCobro/ListarCuentas.vue";
// import MiCuenta from "../views/CuentasDeCobro/MiCuenta.vue";

import Webhook from "../views/Webhook.vue";

// Rutas
import eventosRoutes from "../router/eventos";
import cuentasDeCobroRoutes from "../router/cuentas-de-cobro";


import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/atc_reservas",
    name: "atc_reservas",
    component: Webhook,
    meta: {
      menu: "atc_reservas"
    },
  },
  ...eventosRoutes,
  ...cuentasDeCobroRoutes,
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {

  // Cambia el título de la página según los metadatos de la ruta
  document.title = to.meta.title || "Eventos Cartagena Tips";

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    
    let usuario = localStorage.getItem("usuario");

    if (!usuario) {
      store.commit("eliminarSesion");
      //next({ name: "home" });
      next(to.meta.authRedirect);
    } 
    else {

      store.commit("usuarioActual");

      if (store.state.logueado) {
        next();
      }
    }

  } 
  else {
    next();
  }
});

export default router;

<template>
  <div>
    <Header></Header>
    <div class="container">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <h4 class="mb-3 text-center">
            Cuentas de cobro ({{ cuentas.length }})
          </h4>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-12 text-center">
          <button
            type="button"
            v-on:click="abrirModalAgregarCuenta()"
            class="btn btn-link text-dark mr-2"
            :disabled="!logueado"
          >
            <i class="fas fa-plus"></i> Agregar documento
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <template v-if="cargando">
            <div class="col-sm-12 text-center">
              <h5>Cargando...</h5>
            </div>
          </template>
          <template v-else>
            <template v-if="cuentas.length > 0">
              <div class="list-group mb-5">
                <div
                  v-for="(cuenta, index) of cuentas"
                  :key="index"
                  class="list-group-item text-dark"
                >
                  <div>
                    <p class="card-text mb-0">
                      <strong>Consecutivo:</strong> {{ cuenta.consecutivo }}
                    </p>
                    <p class="card-text mb-0">
                      <strong>Empresa:</strong> {{ cuenta.empresa.nombre }}
                    </p>
                    <p class="card-text mb-0">
                      <strong>Fecha:</strong> {{ formatearFecha(cuenta.fecha) }}
                    </p>
                    <p class="card-text mb-0">
                      <strong>Valor a pagar:</strong> ${{
                        formatearMoneda(cuenta.valor)
                      }}
                    </p>
                  </div>
                  <div class="mt-2">
                    <a
                      href="javascript:void(0)"
                      class="d-block-inline mr-2"
                      v-on:click="generarPDF(cuenta)"
                      >Generar PDF</a
                    >
                    <a
                      href="javascript:void(0)"
                      class="d-block-inline mr-2 text-danger"
                      v-on:click="abrirModalEditarCuenta(cuenta)"
                      >Editar</a
                    >
                    <a
                      href="javascript:void(0)"
                      class="d-block-inline text-danger"
                      v-on:click="eliminar(cuenta)"
                      >Eliminar</a
                    >
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="col-sm-12 text-center">
                <h5>Sin resultados</h5>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
    <Footer></Footer>

    <AgregarCuentaCobro
      :show="showModalCuentaCobro"
      @close="showModalCuentaCobro = false"
    ></AgregarCuentaCobro>
  </div>
</template>

<script>
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import firebase from "firebase/compat/app";

// Modales
import AgregarCuentaCobro from "@/components/modales/AgregarCuentaCobro";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { formatearFecha, formatearMoneda } from "@/helpers/dateHelpers";
import { generarSlug } from "@/helpers/funcionesHelpers";
import axios from "axios";

export default {
  components: {
    AgregarCuentaCobro,
  },
  data() {
    return {
      showModalCuentaCobro: false,
    };
  },

  async created() {
    let usuario = localStorage.getItem("usuario");

    if (!usuario) {
      this.$store.commit("eliminarSesion");
      return;
    }

    try {
      const user = firebase.auth().currentUser;

      if (user) {
        this.$store.commit("usuarioActual", user);
        this.user = user;

        await this.verificarEmpresaAsignada(this.user.phoneNumber);

        await this.obtenerDatosUsuario(this.user.phoneNumber);

        this.fetchCuentas();

      } else {
        this.$store.commit("eliminarSesion");
      }
    } catch (error) {
      console.log("Error al verificar autenticación:", error);
      this.$store.commit("eliminarSesion");
    }
  },

  computed: {
    ...mapState(["logueado", "usuario", "admin", "empresaAsignada", "datosUsuario"]),
    ...mapState("cuentasCobros", ["cargando", "cuentas"]),
    ...mapGetters("cuentasCobros", [
      "getCuentas",
      "editandoCuenta",
      "cuentaSeleccionada",
    ]),
    ...mapGetters("empresas", ["getEmpresas"]),
    cuentas() {
      return this.getCuentas;
    },
  },
  methods: {
    ...mapActions(["verificarAdmin", "verificarEmpresaAsignada", "obtenerDatosUsuario"]),
    ...mapActions("cuentasCobros", [
      "fetchCuentas",
      "eliminarCuenta",
      "asignarCuentaSeleccionada",
      "asignarEditandoCuenta",
    ]),

    async abrirModalAgregarCuenta() {
      // Verificar informacion usuario
      try {
        const telefono = this.usuario.telefono;
        const resultado = await this.verificarInfoUsuario(telefono);
        if (resultado) {
          this.showModalCuentaCobro = true;
          this.asignarEditandoCuenta(false);
        }
      } catch (error) {
        console.log("Error inesperado aqui", error);
      }
    },

    abrirModalEditarCuenta(cuenta) {
      this.showModalCuentaCobro = true;
      this.asignarEditandoCuenta(true);
      const cuentaCopia = { ...cuenta };
      this.asignarCuentaSeleccionada(cuentaCopia);
    },

    async verificarSiEsAdmin() {
      let logueado = firebase.auth().currentUser;
      if (logueado) {
        let usuario = firebase.auth().currentUser.phoneNumber;
        await this.verificarAdmin(usuario);
      }
    },

    async eliminar(cuenta) {
      try {
        await this.eliminarCuenta(cuenta);
        this.$toastr.s("Documento eliminado");
        this.fetchCuentas();
      } catch (error) {
        console.error(error);
      }
    },

    async verificarInfoUsuario(telefono) {
      try {
        const res = await axios.post("/consultar-usuario", {
          telefono: telefono,
          app: "Cuentas de cobro",
        });

        const {
          nombre,
          apellidos,
          tipoDocumento,
          numeroDocumento,
          tengoCuentaBancaria,
          nombreBanco,
          tipoCuentaBancaria,
          numeroCuentaBancaria,
        } = res.data.usuario;

        if (!nombre || !apellidos || !tipoDocumento || !numeroDocumento) {
          this.$toastr.e(
            'Por favor, complete su información en el link "Mis datos" antes de agregar el documento.',
            "Registro de Información"
          );
          return false;
        }

        if (tengoCuentaBancaria === true) {
          if (!nombreBanco || !numeroCuentaBancaria || !tipoCuentaBancaria) {
            this.$toastr.e(
              'Por favor, complete su información en el link "Mis datos" antes de agregar el documento.',
              "Registro de Información"
            );
            return false;
          }
        }

        return true;
      } catch (error) {
        throw error; // Lanzamos el error para que pueda ser capturado fuera de la función
      }
    },

    async generarPDF(cuentaCobro) {

      const docDefinition = await this.crearDocumento(cuentaCobro);
      const nombrePDF = `Cuenta_de_cobro_${this.generarSlug(
        cuentaCobro.usuario.nombre
      )}_${this.generarSlug(cuentaCobro.empresa.nombre)}_${
        cuentaCobro.consecutivo
      }.pdf`;
      pdfMake.createPdf(docDefinition).download(nombrePDF);
      
    },

    async crearDocumento(cuentaCobro) {

      let firmaDataURL = null;
      let footerContent = "";

      if (cuentaCobro.usuario.firma) {
        try {

          const firmaURL = cuentaCobro.usuario.firma;

          // Descargar la imagen utilizando Axios
          const response = await axios.get(firmaURL, {
            responseType: "blob", // Cambiamos a responseType "blob"
          });

          // Convertir el arraybuffer en una Blob
          const firmaBlob = new Blob([response.data], { type: "image/jpeg" });

          // Convertir la Blob en una URL de datos mediante FileReader
          firmaDataURL = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(firmaBlob);
          });

        } catch (error) {
          console.error("Error al descargar la imagen:", error);
        }
      }

      return {
        content: [
          {
            table: {
              widths: ["50%", "50%"],
              body: [
                [
                  {
                    text: `Cuenta de cobro No. ${cuentaCobro.consecutivo}`,
                    style: "normal",
                    alignment: "left",
                  },
                  {
                    text: `Fecha: ${formatearFecha(cuentaCobro.fecha)}`,
                    style: "normal",
                    alignment: "right",
                  },
                ],
              ],
            },
            layout: {
              defaultBorder: false, // Elimina los bordes de la tabla si no son necesarios
            },
            margin: [0, 0, 0, 20],
          },

          // Contenedor para header
          {
            stack: [
              {
                text: cuentaCobro.empresa.nombre.toUpperCase(),
                style: "header",
                alignment: "center",
              },
              {
                text: `NIT ${cuentaCobro.empresa.nit}`,
                style: "header",
                alignment: "center",
              },
              {
                text: cuentaCobro.empresa.direccion,
                style: "header",
                alignment: "center",
              },
            ],
            margin: [0, 0, 0, 20],
          },

          // Contenedor debe a
          {
            stack: [
              {
                text: "DEBE A:",
                style: "header",
                alignment: "center",
              },
            ],
            margin: [0, 0, 0, 20],
          },

          // Contenedor para subheader
          {
            stack: [
              {
                text: `${cuentaCobro.usuario?.nombre?.toUpperCase()} ${cuentaCobro.usuario?.apellidos?.toUpperCase()}`,
                style: "normal",
                alignment: "center",
              },
              {
                text: `${cuentaCobro.usuario.tipoDocumento} ${cuentaCobro.usuario.numeroDocumento}`,
                style: "normal",
                alignment: "center",
              },
              {
                text: `Teléfono: ${cuentaCobro.usuario.telefono}`,
                style: "normal",
                alignment: "center",
              },
            ],
            margin: [0, 0, 0, 20],
          },

          // Contenedor para Normal
          {
            stack: [
              {
                text: "CONCEPTO",
                style: "normal",
                alignment: "center",
                bold: true,
              },
              {
                text: cuentaCobro.concepto,
                style: "normal",
                fontSize: 14,
                alignment: "center",
              },
            ],
            margin: [0, 0, 0, 20],
          },

          // Contenedor Valor a pagar
          {
            stack: [
              {
                text: "VALOR TOTAL A PAGAR",
                style: "normal",
                alignment: "center",
                bold: true,
              },
              {
                text:
                  cuentaCobro.valor > 0
                    ? `$${formatearMoneda(cuentaCobro.valor)}`
                    : "",
                style: "normal",
                alignment: "center",
              },
            ],
            margin: [0, 0, 0, 20],
          },

          // Contenedor cuenta de cobro
          {
            stack: [
              {
                text: cuentaCobro.usuario.tengoCuentaBancaria
                  ? `Favor consignar en la ${cuentaCobro.usuario.tipoCuentaBancaria.toLowerCase()} ${
                      cuentaCobro.usuario.numeroCuentaBancaria
                    } de ${cuentaCobro.usuario.nombreBanco}.`
                  : "",
                style: "normal",
                alignment: "center",
                bold: false,
              },
            ],
            margin: [0, 0, 0, 30],
          },
        ],

        footer: {
          stack: [
            ...(firmaDataURL
              ? [
                  {
                    text: "Cordialmente:\n",
                    fontSize: 14,
                    alignment: "left",
                  },
                  {
                    image: firmaDataURL,
                    width: 120,
                    alignment: "left",
                    margin: [0, 10, 0, 0],
                  },
                  {
                    text: "___________________________\n",
                    fontSize: 14,
                    alignment: "left",
                  },
                  {
                    text: `${cuentaCobro.usuario?.nombre?.toUpperCase()} ${cuentaCobro.usuario?.apellidos?.toUpperCase()}\n`,
                    fontSize: 14,
                    alignment: "left",
                  },
                  {
                    text: `${cuentaCobro.usuario.tipoDocumento} ${cuentaCobro.usuario.numeroDocumento}\n`,
                    fontSize: 14,
                    alignment: "left",
                  },
                  {
                    text: `Teléfono: ${cuentaCobro.usuario.telefono}\n\n\n`,
                    fontSize: 14,
                    alignment: "left",
                  },
                ]
              : [
                  {
                    text: "Cordialmente:\n",
                    fontSize: 14,
                    alignment: "left",
                  },
                  {
                    text: "        \n",
                    width: 120,
                    alignment: "left",
                    margin: [0, 10, 0, 0],
                  },
                  {
                    text: "___________________________\n",
                    fontSize: 14,
                    alignment: "left",
                  },
                  {
                    text: `${cuentaCobro.usuario?.nombre?.toUpperCase()} ${cuentaCobro.usuario?.apellidos?.toUpperCase()}\n`,
                    fontSize: 14,
                    alignment: "left",
                  },
                  {
                    text: `${cuentaCobro.usuario.tipoDocumento} ${cuentaCobro.usuario.numeroDocumento}\n`,
                    fontSize: 14,
                    alignment: "left",
                  },
                  {
                    text: `Teléfono: ${cuentaCobro.usuario.telefono}\n\n\n`,
                    fontSize: 14,
                    alignment: "left",
                  },
                ]),
            {
              text: "Nota: con el fin de dar cumplimiento a las disposiciones establecidas en la Ley 1819 de 2016 y del parágrafo 2 del artículo 383 del Estatuto Tributario, manifiesto bajo gravedad de juramento que para efectos de la aplicación de la tabla de retención en la fuente establecida en el artículo 383 del Estatuto Tributario, la cual se le aplica a los pagos o abonos en cuenta por concepto de ingresos por honorarios y por compensación por servicios personales, “No he contratado o vinculado más de un trabajador asociado a mi actividad económica por al menos noventa (90) días continuos o discontinuos”. (Parágrafo 2 art.383 E.T.)",
              fontSize: 10,
              alignment: "justify",
            },
          ],
          alignment: "center",
          fontSize: 10,
          margin: [50, -220, 50, 0],
        },

        styles: {
          header: {
            fontSize: 14,
            bold: true,
            margin: [0, 0, 0, 10],
          },
          subheader: {
            fontSize: 14,
            bold: true,
            margin: [0, 0, 0, 10],
          },
          normal: {
            fontSize: 14,
            margin: [0, 0, 0, 5],
          },
        },
        pageMargins: [50, 50, 50, 50],
      };
    },

    // Helpers
    formatearFecha,
    formatearMoneda,
    generarSlug,
  },
};
</script>

<template>
  <div>
    <div class="pt-4 text-center">
      <template v-if="logueado === true">
        <h4 class="mb-0" v-html="'¡Hola, <break></break>' + ( datosUsuario.nombre ? datosUsuario.nombre : usuario.telefono) + '!'"></h4>
        <small
          v-if="
            $route.meta.role === 'empresa' &&
            empresaAsignada &&
            empresaAsignada.nombre
          "
          >{{ empresaAsignada.nombre }}</small
        >
      </template>
      <template v-else>
        <h4 class="mb-0">Te damos la bienvenida</h4>
      </template>
      <template v-if="logueado === false">
        <button type="button" @click="showModal = true" class="btn btn-link">
          Registrarme / Iniciar Sesión
        </button>
      </template>

      <Menu_eventos v-if="menuActual === 'eventos'"></Menu_eventos>

      <template>
        <div v-if="$route.meta.role && !$route.meta.esHome">
          <router-link
            v-if="$route.meta.role === 'empresa'"
            to="/cuentasdecobro/empresa"
            class="btn btn-link text-bold text-dark"
            >Volver al Menú</router-link
          >
          <router-link
            v-else
            to="/cuentasdecobro"
            class="btn btn-link text-bold text-dark"
            >Volver al Menú</router-link
          >
          <div class="py-3">---</div>
        </div>
      </template>
    </div>
    <modal :show="showModal" @close="showModal = false"></modal>
  </div>
</template>

<script>
import Modal from "./Modal_registro";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    Modal,
  },
  props: {
    theme: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      showModal: false,
      menuActual: "",
    };
  },
  created() {
    this.verificarMenu();
  },
  computed: {
    ...mapState([
      "logueado",
      "usuario",
      "admin",
      "empresaAsignada",
      "datosUsuario"
    ]),
  }, 
  methods: {
    verificarMenu() {
      const rutaActual = this.$route;
      this.menuActual = rutaActual.meta.menu;
    },
    cerrarSesion() {
      this.$store.commit("cerrarSesionUsuarioPorTokenVencido");
    },
  },
};
</script>

<style>
.text-bold {
  font-weight: bold;
}
</style>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import moment from "moment";

export default {
  props: {
    show: Boolean,
    editar: Boolean,
  },
  computed: {
    ...mapState(["logueado", "usuario", "admin"]),
    ...mapState("eventos", ["categoriasEventos","organizador","editandoEvento", "eventoSeleccionado","tipoEvento"]),
  },
  data() {
    return {
      showModal: false,
      nuevoEvento: {
        nombre: "",
        tipo: "",
        categoria: "",
        fecha: "",
        lugar: "",
        organizador: "",
        portada: "",
        descripcion: "",
        instagram: "",
        direccion: "",
        tipoPublico: "",
        precio: "",
        usuario: null,
      },
    };
  },
  created() {
    
  },
  watch: {
    organizador(newOrganizador) {
      this.nuevoEvento.organizador = newOrganizador.nombre;
      this.nuevoEvento.instagram = newOrganizador.instagram;
    },
    tipoEvento(newTipoEvento) {
      this.nuevoEvento.tipo = newTipoEvento;
    },
    usuario(newUsuario) {
      this.nuevoEvento.usuario = newUsuario.telefono;
    },
  },
  methods: {
    ...mapActions("eventos", ["fetchEventos","agregarEvento","actualizarEvento"]),

    async agregar() {
       
      // Validar campos 
      if(!this.validarCampos(this.nuevoEvento)){
          return;
      }

      // Agregar evento
      try {
        // Formatear fecha antes en enviar al backend
        this.nuevoEvento.fecha = this.formatearFecha(this.nuevoEvento.fecha);

        // Agregar evento
        const response = await this.agregarEvento(this.nuevoEvento);

        this.$toastr.s("Evento creado con éxito");

        // Limpiar campos
        this.nuevoEvento.nombre = "";
        this.nuevoEvento.tipo = "";
        this.nuevoEvento.categoria = "";
        this.nuevoEvento.fecha = "";
        this.nuevoEvento.lugar = "";

        if (!this.organizador) {
          this.nuevoEvento.organizador = "";
          this.nuevoEvento.instagram = "";
        }

        this.nuevoEvento.descripcion = "";
        this.nuevoEvento.direccion = "";
        this.nuevoEvento.tipoPublico = "";
        this.nuevoEvento.precio = "";

        this.fetchEventos();
        this.cerrar();
      }
      catch (error) {
        this.$toastr.e("Error al crear el evento");  
        console.error("Error", error);
      }
    },
    async actualizar() {

      // Validar campos 
      if(!this.validarCampos(this.eventoSeleccionado)){
          return;
      }

      // Actualizar evento
      try {

        // Formatear fecha antes en enviar al backend
        this.eventoSeleccionado.fecha = this.formatearFecha(
          this.eventoSeleccionado.fecha
        );

        // Actualizar evento
        const response = await this.actualizarEvento(this.eventoSeleccionado);
        this.$toastr.s("Evento actualizado con éxito");
        this.fetchEventos();
        this.cerrar();

      } 
      catch (error) {
        this.$toastr.e("Error al actualizar el evento");  
        console.error("Error", error);
      }
    },
    validarCampos(datos){

      // Validar si existe conexion a internet
      if(!navigator.onLine){
          this.$toastr.e("No hay conexión a Internet, inténtalo más tarde.");
          return false;
      }
      // Validar campos vacios
      else if (datos.nombre.trim() === "") {
          this.$toastr.e("El campo Nombre es requerido");
          return false;
      } 
      else if (datos.categoria.trim() === "") {
          this.$toastr.e("El campo Tipo de evento es requerido");
          return false;
      } 
      else if (datos.fecha.trim() === "") {
          this.$toastr.e("El campo Fecha y hora es requerido");
          return false;
      } 
      else if (datos.lugar.trim() === "") {
          this.$toastr.e("El campo Lugar es requerido");
          return false;
      }

      return true;
   
    },
    validarPermisosAdminYOrganizador() {

      if (this.admin) {
        return false;
      } 
      else if (
        this.organizador &&
        this.organizador.nombre &&
        this.organizador.instagram
      ) 
      {
        return true;
      } 
      else {
        return false;
      }
    },
    formatearFecha(fecha) {
      return new Date(fecha);
    },

    handlePortadaChange(event) {
      const file = event.target.files[0];

      if (!file) {
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        if (this.editandoEvento) {
          this.eventoSeleccionado.portada = e.target.result;
        } else {
          this.nuevoEvento.portada = e.target.result;
        }
      };

      reader.readAsDataURL(file);
    },

    cerrar() {
      this.$emit("close");
    },
  },
};
</script>

<template>
  <div>
    <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <div v-if="editandoEvento">
                <div v-text="'Editar Evento'"></div>
              </div>
              <div v-else>
                <div v-text="'Agregar Evento'"></div>
              </div>
              <div class="close-reg" @click="cerrar()">
                <font-awesome-icon :icon="['fas', 'times']" />
              </div>
            </div>
            <div class="modal-body">
              <template v-if="editandoEvento">
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Nombre:"
                    class="form-control"
                    v-model="eventoSeleccionado.nombre"
                  />
                </div>
                <div class="form-group">
                  <select
                    class="form-control"
                    id="tipoEvento"
                    v-model="eventoSeleccionado.categoria"
                  >
                    <option value="">Tipo de evento</option>
                    <option
                      v-for="(categoria, index) of categoriasEventos"
                      :key="index"
                      :value="categoria.nombre"
                    >
                      {{ categoria.nombre }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="Fecha" class="form-label">Fecha y hora:</label>
                  <input
                    type="datetime-local"
                    class="form-control"
                    v-model="eventoSeleccionado.fecha"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Lugar:"
                    class="form-control"
                    v-model="eventoSeleccionado.lugar"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Dirección:"
                    class="form-control"
                    v-model="eventoSeleccionado.direccion"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Tipo publico:"
                    class="form-control"
                    v-model="eventoSeleccionado.tipoPublico"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Precio:"
                    class="form-control"
                    v-model="eventoSeleccionado.precio"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    :readonly="validarPermisosAdminYOrganizador()"
                    placeholder="Organizador:"
                    class="form-control"
                    v-model="eventoSeleccionado.organizador"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    :readonly="validarPermisosAdminYOrganizador()"
                    placeholder="Cuenta Instagram:"
                    class="form-control"
                    v-model="eventoSeleccionado.instagram"
                  />
                </div>

                <template v-if="eventoSeleccionado.portada">
                  <div class="form-group">
                    <label>Portada actual:</label>
                    <img
                      :src="eventoSeleccionado.portada"
                      alt="Portada actual"
                      width="100"
                    />
                  </div>
                </template>

                <div class="form-group">
                  <label for="firma">{{
                    eventoSeleccionado.portada
                      ? "Cargar nueva portada"
                      : "Cargar portada"
                  }}</label>
                  <input
                    type="file"
                    class="form-control-file border"
                    id="portada"
                    placeholder="Adjuntar portada"
                    @change="handlePortadaChange"
                  />
                </div>

                <div class="form-group">
                  <textarea
                    maxlength="500"
                    class="form-control"
                    placeholder="Descripcion:"
                    v-model="eventoSeleccionado.descripcion"
                  ></textarea>
                </div>

                <input
                  class="btn btn-secondary"
                  type="button"
                  value="Actualizar"
                  v-on:click="actualizar()"
                />
              </template>

              <template v-else>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Nombre:"
                    class="form-control"
                    v-model="nuevoEvento.nombre"
                  />
                </div>
                <div class="form-group">
                  <select
                    class="form-control"
                    id="tipoEvento"
                    v-model="nuevoEvento.categoria"
                  >
                    <option value="">Tipo de evento</option>
                    <option
                      v-for="(categoria, index) of categoriasEventos"
                      :key="index"
                      :value="categoria.nombre"
                    >
                      {{ categoria.nombre }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="Fecha" class="form-label">Fecha y hora:</label>
                  <input
                    type="datetime-local"
                    class="form-control"
                    v-model="nuevoEvento.fecha"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Lugar:"
                    class="form-control"
                    v-model="nuevoEvento.lugar"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Dirección:"
                    class="form-control"
                    v-model="nuevoEvento.direccion"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Tipo publico:"
                    class="form-control"
                    v-model="nuevoEvento.tipoPublico"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Precio:"
                    class="form-control"
                    v-model="nuevoEvento.precio"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    :readonly="validarPermisosAdminYOrganizador()"
                    placeholder="Organizador:"
                    class="form-control"
                    v-model="nuevoEvento.organizador"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    :readonly="validarPermisosAdminYOrganizador()"
                    placeholder="Cuenta Instagram:"
                    class="form-control"
                    v-model="nuevoEvento.instagram"
                  />
                </div>

                <template v-if="nuevoEvento.portada">
                  <div class="form-group">
                    <label>Portada actual:</label>
                    <img
                      :src="nuevoEvento.portada"
                      alt="Portada actual"
                      width="100"
                    />
                  </div>
                </template>
                
                
                <div class="form-group">
                  <label for="firma">Cargar portada</label>
                  <input
                    type="file"
                    class="form-control-file border"
                    id="portada"
                    placeholder="Adjuntar su portada"
                    @change="handlePortadaChange"
                  />
                </div>

                <div class="form-group">
                  <textarea
                    maxlength="500"
                    class="form-control"
                    placeholder="Descripcion:"
                    v-model="nuevoEvento.descripcion"
                  ></textarea>
                </div>

                <input
                  class="btn btn-secondary"
                  type="button"
                  value="Agregar"
                  v-on:click="agregar()"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.modal-container {
  max-height: calc(100vh - 60px);
  overflow-y: auto;
}

.scroll {
  max-height: 500px;
  overflow-y: scroll;
}

.disabled {
  opacity: 0.6;
}

.close-reg {
  text-align: end;
  font-size: 18px;
  color: grey;
  cursor: pointer;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  line-height: 1.3 !important;
}

.modal-container {
  width: 480px;
  margin: 0 auto;
  padding: 10px 10px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header {
  padding: 0.5rem 1rem;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

a.buttons-mobile-section__bg-whatsapp {
  background-color: #25d366;
}

.button-whatsapp-modal-desktop {
  padding: 4px 10px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
}

a.button-whatsapp-modal-desktop:hover {
  transition: 0.3s;
  background-color: #1ea74f;
}

@media (max-width: 400px) {
  .modal-container {
    width: 350px;
  }
}

@media (min-width: 400px) and (max-width: 500px) {
  .modal-container {
    width: 400px;
  }
}

@media (min-width: 500px) and (max-width: 600px) {
  .modal-container {
    width: 500px;
  }
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>

import axios from 'axios';

axios.defaults.baseURL = 'https://us-central1-ctips-e397d.cloudfunctions.net/ctips';

export default {
    namespaced: true,
    state: {
        organizadores: [],
        organizador: null,
        cargando: true    
    },
    mutations: {
        SET_ORGANIZADORES(state, organizadores){
          state.organizadores = organizadores;
        }, 
        SET_ORGANIZADOR(state, organizador){
          state.organizador = organizador;
        },
        SET_CARGANDO(state, valor){
          state.cargando = valor;
        }, 
    },
    actions: {       

        fetchOrganizadores({commit}){
            axios.get('/listar-organizadores')
            .then(response => {
               commit('SET_ORGANIZADORES', response.data.organizadores);
               commit('SET_CARGANDO', false);
            })
            .catch(error => {
               console.error(error);
            });
        },
      
        agregarOrganizador({ commit }, organizador) {
            return new Promise((resolve, reject) => {
              axios.post('/crear-organizador', organizador)
                .then(response => {
                   resolve('ok');
                })
                .catch(error => {
                   reject(error);
                });
            });
        },
        
        eliminarOrganizador({ commit }, organizador) {
            return new Promise((resolve, reject) => {
              axios.delete('/eliminar-organizador/' + organizador.id)
                .then(response => {
                  resolve('ok');
                })
                .catch(error => {
                  reject(error);
                });
            });
        },

    },
    getters: {
        getOrganizadores(state){
            return state.organizadores;
        } 
    }

}

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  props: {
    show: Boolean,
    editar: Boolean,
  },
  computed: {
    ...mapState(["logueado", "usuario", "admin"]),
    ...mapState("empresas", ["editandoEmpresa", "empresaSeleccionada"]),
  },
  data() {
    return {
      showModal: false,
      nuevaEmpresa: {
        nombre: "",
        direccion: "",
        nit: "",
        telefono: "",
      },
    };
  },
  methods: {
    ...mapActions("empresas", [
      "fetchEmpresas",
      "agregarEmpresa",
      "actualizarEmpresa",
    ]),
    async agregar() {
      if (!this.validarCampos(this.nuevaEmpresa)) {
        return;
      }

      try {
        // Agregar usuario actual
        this.nuevaEmpresa.usuario = this.usuario.telefono;

        await this.agregarEmpresa(this.nuevaEmpresa);

        // Limpiar campos
        this.nuevaEmpresa.nombre = "";
        this.nuevaEmpresa.direccion = "";
        this.nuevaEmpresa.nit = "";
        this.nuevaEmpresa.telefono = "";

        this.fetchEmpresas();
        this.cerrar();

        this.$toastr.s("Empresa agregada");
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.$toastr.e(error.response.data.mensaje);
        } else {
          this.$toastr.e("Error inesperado");
        }
      }
    },
    async actualizar() {
      if (!this.validarCampos(this.empresaSeleccionada)) {
        return;
      }

      try {
        await this.actualizarEmpresa(this.empresaSeleccionada);

        // Limpiar campos
        this.empresaSeleccionada.nombre = "";
        this.empresaSeleccionada.direccion = "";
        this.empresaSeleccionada.nit = "";
        this.empresaSeleccionada.telefono = "";

        this.fetchEmpresas();
        this.cerrar();

        this.$toastr.s("Empresa actualizada");
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.$toastr.e(error.response.data.mensaje);
        } else {
          this.$toastr.e("Error inesperado");
        }
      }
    },
    validarCampos(datos) {
      if (datos.nombre.trim() === "") {
        this.$toastr.e("El campo Nombre es requerido");
        return false;
      } else if (datos.direccion.trim() === "") {
        this.$toastr.e("El campo Direccion es requerido");
        return false;
      } else if (datos.nit.trim() === "") {
        this.$toastr.e("El campo NIT es requerido");
        return false;
      } else if (datos.telefono.trim() === "") {
        this.$toastr.e("El campo Telefono es requerido");
        return false;
      }
      return true;
    },
    cerrar() {
      this.$emit("close");
    },
  },
};
</script>

<template>
  <div>
    <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <div v-if="editandoEmpresa">
                <div v-text="'Editar Cliente'"></div>
              </div>
              <div v-else>
                <div v-text="'Agregar Cliente'"></div>
              </div>
              <div class="close-reg" @click="cerrar()">
                <font-awesome-icon :icon="['fas', 'times']" />
              </div>
            </div>
            <div class="modal-body">
              <template v-if="editandoEmpresa">
                <div class="form-group">
                  <label for="">Nombre:</label>
                  <input
                    type="text"
                    placeholder="Nombre:"
                    class="form-control"
                    v-model="empresaSeleccionada.nombre"
                  />
                </div>
                <div class="form-group">
                  <label for="">Dirección:</label>
                  <input
                    type="text"
                    placeholder="Direccion:"
                    class="form-control"
                    v-model="empresaSeleccionada.direccion"
                  />
                </div>
                <div class="form-group">
                  <label for="">NIT:</label>
                  <input
                    type="text"
                    placeholder="NIT:"
                    class="form-control"
                    v-model="empresaSeleccionada.nit"
                  />
                </div>
                <div class="form-group">
                  <label for="">Teléfono:</label>
                  <input
                    type="text"
                    placeholder="Telefono:"
                    class="form-control"
                    v-model="empresaSeleccionada.telefono"
                  />
                </div>
                <input
                  class="btn btn-secondary"
                  type="button"
                  value="Actualizar"
                  v-on:click="actualizar()"
                />
              </template>
              <template v-else>
                <div class="form-group">
                  <label for="">Nombre:</label>
                  <input
                    type="text"
                    placeholder="Nombre:"
                    class="form-control"
                    v-model="nuevaEmpresa.nombre"
                  />
                </div>
                <div class="form-group">
                  <label for="">Dirección:</label>
                  <input
                    type="text"
                    placeholder="Direccion:"
                    class="form-control"
                    v-model="nuevaEmpresa.direccion"
                  />
                </div>
                <div class="form-group">
                  <label for="">NIT:</label>
                  <input
                    type="text"
                    placeholder="NIT:"
                    class="form-control"
                    v-model="nuevaEmpresa.nit"
                  />
                </div>
                <div class="form-group">
                  <label for="">Teléfono:</label>
                  <input
                    type="text"
                    placeholder="Telefono:"
                    class="form-control"
                    v-model="nuevaEmpresa.telefono"
                  />
                </div>
                <input
                  class="btn btn-secondary"
                  type="button"
                  value="Agregar"
                  v-on:click="agregar()"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.modal-container {
  max-height: calc(100vh - 60px);
  overflow-y: auto;
}

.scroll {
  max-height: 500px;
  overflow-y: scroll;
}

.disabled {
  opacity: 0.6;
}

.close-reg {
  text-align: end;
  font-size: 18px;
  color: grey;
  cursor: pointer;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  line-height: 1.3 !important;
}

.modal-container {
  width: 480px;
  margin: 0 auto;
  padding: 10px 10px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header {
  padding: 0.5rem 1rem;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

a.buttons-mobile-section__bg-whatsapp {
  background-color: #25d366;
}

.button-whatsapp-modal-desktop {
  padding: 4px 10px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
}

a.button-whatsapp-modal-desktop:hover {
  transition: 0.3s;
  background-color: #1ea74f;
}

@media (max-width: 400px) {
  .modal-container {
    width: 350px;
  }
}

@media (min-width: 400px) and (max-width: 500px) {
  .modal-container {
    width: 400px;
  }
}

@media (min-width: 500px) and (max-width: 600px) {
  .modal-container {
    width: 500px;
  }
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>

import Home from '@/views/Eventos/Home'
import Organizadores from '@/views/Eventos/Organizadores'

const eventosRoutes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      menu: "eventos",
      esHome: true,
    },
  },
  {
    path: "/organizadores",
    name: "organizadores",
    component: Organizadores,
    meta: {
      menu: "eventos",
      requiresAuth: true,
      authRedirect: "/"      
    },
  },
];

export default eventosRoutes;

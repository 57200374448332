import axios from "axios";

axios.defaults.baseURL =
  "https://us-central1-ctips-e397d.cloudfunctions.net/ctips";

export default {
  namespaced: true,
  state: {
    empresa: null,
    empresas: [],
    empresaSeleccionada: null,
    editandoEmpresa: false,
    cargando: true,
  },
  mutations: {
    SET_EMPRESAS(state, empresas) {
      state.empresas = empresas;
    },
    SET_CARGANDO(state, valor) {
      state.cargando = valor;
    },
    SET_EMPRESA(state, empresa) {
      state.empresa = empresa;
    },
    SET_EMPRESA_SELECCIONADA(state, empresa) {
      state.empresaSeleccionada = empresa;
    },
    SET_EDITANDO_EMPRESA(state, valor) {
      state.editandoEmpresa = valor;
    },
  },
  actions: {
    fetchEmpresas({ rootState, commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/listar-empresas", rootState.usuario)
          .then((response) => {
            commit("SET_EMPRESAS", response.data.empresas);
            commit("SET_CARGANDO", false);
            resolve("ok");
          })
          .catch((error) => {
            console.error("Error", error);
            reject(error);
          });
      });
    },

    agregarEmpresa({ commit }, empresa) {
      return new Promise((resolve, reject) => {
        axios
          .post("/crear-empresa", empresa)
          .then((response) => {
            resolve("ok");
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    asignarEmpresaSeleccionada({ commit }, empresa) {
      commit("SET_EMPRESA_SELECCIONADA", empresa);
    },

    asignarEditandoEmpresa({ commit }, valor) {
      commit("SET_EDITANDO_EMPRESA", valor);
    },

    actualizarEmpresa({ commit }, empresaActualizada) {
      return new Promise((resolve, reject) => {
        axios
          .put("/actualizar-empresa", empresaActualizada)
          .then((response) => {
            resolve("ok");
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    eliminarEmpresa({ commit }, empresa) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/eliminar-empresa/" + empresa.id)
          .then((response) => {
            resolve("ok");
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    getEmpresas(state) {
      return state.empresas;
    },
  },
};

<script>
    import { mapState, mapMutations, mapActions } from "vuex";
    import moment from 'moment';

    export default {
      props: {
        show: Boolean,
        editar: Boolean
      },
      computed:{  
        ...mapState(['logueado', 'usuario', 'admin']),
        ...mapState('organizadores', [])  
      },
      data(){
        return {
          showModal: false,
          nuevoOrganizador:{
            nombre: '',
            cuentaIG: '',
            usuario: ''
          }
        }
      },   
      methods :{ 
        ...mapActions('organizadores', ['fetchOrganizadores', 'agregarOrganizador']),  
        
        async agregar(){   

             // Validar campos vacios
            if (this.nuevoOrganizador.nombre.trim() === '') {
              this.$toastr.e("El campo Nombre es requerido");
              return;
            }
            else if (this.nuevoOrganizador.cuentaIG.trim() === '') {
              this.$toastr.e("El campo Cuenta Instagram es requerido");
              return;
            }
            else if (this.nuevoOrganizador.usuario.trim() === '') {
              this.$toastr.e("El campo Usuario es requerido");
              return;
            }  
          
            // Agregar
            try {   
                
                await this.agregarOrganizador(this.nuevoOrganizador);  
                 
                // Limpiar campos          
                this.nuevoOrganizador.nombre = '';
                this.nuevoOrganizador.cuentaIG = '';
                this.nuevoOrganizador.usuario = '';  

                this.fetchOrganizadores();
                this.cerrar();

                this.$toastr.s("Organizador agregado");  
            } 
            catch (error) {
                console.error(error);
            }
        },   
        cerrar(){
           this.$emit('close');
        }
      }      
    }
    </script>
    
    <template>
      <div>
        <Transition name="modal">
          <div v-if="show" class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-container">
                <div class="modal-header">
                    <div>
                        <div v-text="'Agregar Organizador'"></div> 
                    </div>
                    <div class="close-reg" @click="cerrar()"><font-awesome-icon :icon="['fas', 'times']"/></div>
                </div>    
                <div class="modal-body">               

                  <template>
                        <div class="form-group">
                            <input type="text"  placeholder="Nombre:" class="form-control" v-model="nuevoOrganizador.nombre">
                        </div>              
                        <div class="form-group">
                            <input type="text" placeholder="Cuenta Instagram:" class="form-control" v-model="nuevoOrganizador.cuentaIG">
                        </div>   
                        <div class="form-group">
                            <input type="text" placeholder="Usuario:" class="form-control" v-model="nuevoOrganizador.usuario">
                        </div>  
                        <input class="btn btn-secondary" type="button" value="Agregar" v-on:click="agregar()">
                  </template> 

                </div>      
              </div>
            </div>
          </div>
        </Transition>
    </div>        
    </template>
    
    <style scoped> 

    .modal-container{
      max-height: calc(100vh - 60px);
      overflow-y: auto;
    }  

    .scroll {
      max-height: 500px; 
      overflow-y: scroll;    
    }
 
    .disabled {
      opacity: .6;
    }    
      
    .close-reg{
      text-align: end;
      font-size: 18px;
      color: grey;
      cursor: pointer;
    }           
    
    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: table;
      transition: opacity 0.3s ease;
    }
    
    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
      line-height: 1.3!important;
    }
    
    .modal-container {
      width: 480px;
      margin: 0 auto;
      padding: 10px 10px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
    }
    
    .modal-header{
      padding: 0.5rem 1rem;
    }
    
    .modal-header h3 {
      margin-top: 0;
      color: #42b983;
    }  
    
    a.buttons-mobile-section__bg-whatsapp {
      background-color: #25d366;
    }
    
    .button-whatsapp-modal-desktop {
      padding: 4px 10px;
      display: flex;
      align-items: center;
      border-radius: 25px;
      color: #fff;
      font-size: 1.2rem;
      font-weight: bold;
    }
    
    a.button-whatsapp-modal-desktop:hover {
      transition: .3s;
      background-color: #1ea74f;
    }
    
    @media (max-width: 400px){
      .modal-container {
        width: 350px;
      }
    }
    
    @media (min-width: 400px) and (max-width: 500px){
      .modal-container {
        width: 400px;
      }
    }
    
    @media (min-width: 500px) and (max-width: 600px){
      .modal-container {
        width: 500px;
      }
    }
     
    .modal-enter-from .modal-container,
    .modal-leave-to .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    </style>
export const traerUsuarioLocal = () => {

    const usuario = localStorage.getItem('usuario');

    if( !usuario ) {
        return null;
    }

    return JSON.parse( usuario );

}

<script>
import axios from "axios";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { obtenerFechaActual } from "../../helpers/funcionesHelpers";

export default {
  props: {
    show: Boolean,
    editar: Boolean,
  },
  data() {
    return {
      showModal: false,
      nuevaCuenta: {
        empresa: "",
        fecha: "",
        concepto: "",
        valor: "",
      },
    };
  },
  mounted() {
    this.fetchEmpresas();
    this.nuevaCuenta.fecha = this.obtenerFechaActual();
  },
  computed: {
    ...mapState(["logueado", "usuario", "admin", "empresaAsignada"]),
    ...mapState("cuentasCobros", [
      "cuentas",
      "editandoCuenta",
      "cuentaSeleccionada",
    ]),
    ...mapGetters("cuentasCobros", ["getCuentas"]),
    ...mapState("empresas", ["empresas"]),
  },
  methods: {
    ...mapActions("cuentasCobros", [
      "fetchCuentas",
      "agregarCuenta",
      "actualizarCuenta",
    ]),
    ...mapActions("empresas", ["fetchEmpresas", "eliminarEmpresa"]),
    ...mapActions(["verificarAdmin", "verificarEmpresaAsignada"]),

    async agregar() {
      if (!this.validarCampos(this.nuevaCuenta)) {
        return;
      }

      try {
        const res = await axios.post("/consultar-usuario", {
          telefono: this.usuario.telefono,
          app: "Cuentas de cobro",
        });

        const { usuario } = res.data;

        // Agregar usuario actual
        this.nuevaCuenta.usuario = usuario;

        await this.agregarCuenta(this.nuevaCuenta);

        // Limpiar campos
        this.nuevaCuenta.empresa = "";
        this.nuevaCuenta.concepto = "";
        this.nuevaCuenta.valor = "";

        this.fetchCuentas();
        this.cerrar();

        this.$toastr.s("Documento agregado");
      } catch (error) {
        console.log(error);
        this.$toastr.e("Error inesperado");
      }
    },

    async actualizar() {
      if (!this.validarCampos(this.cuentaSeleccionada)) {
        return;
      }

      try {
        const res = await axios.post("/consultar-usuario", {
          telefono: this.usuario.telefono,
          app: "Cuentas de cobro",
        });

        const { usuario } = res.data;

        // Agregar usuario actual
        this.cuentaSeleccionada.usuario = usuario;

        await this.actualizarCuenta(this.cuentaSeleccionada);

        // Limpiar campos
        this.cuentaSeleccionada.empresa = "";
        this.cuentaSeleccionada.concepto = "";
        this.cuentaSeleccionada.valor = "";

        this.fetchCuentas();
        this.cerrar();

        this.$toastr.s("Documento actualizado");
      } 
      catch (error) {
          if (error.response && error.response.status === 400) {
            // Mostrar un mensaje de error específico para el caso de consecutivo duplicado
            this.$toastr.e("El consecutivo ya existe para este usuario");
          } else {
            // En caso de otros errores, mostrar un mensaje genérico
            this.$toastr.e("Error inesperado");
          }
      }
    },
    validarCampos(datos) {
      if (!datos.empresa || Object.keys(datos.empresa).length === 0) {
        this.$toastr.e("El campo Empresa es requerido");
        return false;
      } else if (datos.fecha.trim() === "") {
        this.$toastr.e("El campo Fecha es requerido");
        return false;
      } else if (datos.concepto.trim() === "") {
        this.$toastr.e("El campo Concepto es requerido");
        return false;
      } else if (datos.valor.trim() === "") {
        this.$toastr.e("El campo Valor es requerido");
        return false;
      }

      return true;
    },
    cerrar() {
      this.$emit("close");
    },
    obtenerFechaActual,
  },
};
</script>

<template>
  <div>
    <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <div v-if="editandoCuenta">
                <div v-text="'Editar cuenta de cobro'"></div>
              </div>
              <div v-else>
                <div v-text="'Agregar cuenta de cobro'"></div>
              </div>
              <div class="close-reg" @click="cerrar()">
                <font-awesome-icon :icon="['fas', 'times']" />
              </div>
            </div>
            <div class="modal-body">
              <template v-if="editandoCuenta">
                <div class="form-group">
                  <label for="">Empresa:</label>
                  <select
                    class="form-control"
                    v-model="cuentaSeleccionada.empresa"
                  >
                    <option value="" selected>Empresa</option>
                    <option
                      v-for="(empresa, index) of empresas"
                      :key="index"
                      :value="empresa"
                    >
                      {{ empresa.nombre }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="">Fecha de emisión:</label>
                  <input
                    type="date"
                    placeholder="Fecha de emisión:"
                    class="form-control"
                    v-model="cuentaSeleccionada.fecha"
                  />
                </div>
                <div class="form-group">
                  <label>Concepto:</label>
                  <textarea
                    placeholder="Concepto:"
                    class="form-control"
                    rows="5"
                    v-model="cuentaSeleccionada.concepto"
                  ></textarea>
                </div>

                <div class="form-group">
                  <label>Consecutivo:</label>
                  <input
                    type="number"
                    placeholder="Consecutivo:"
                    class="form-control"
                    v-model="cuentaSeleccionada.consecutivo"
                  />
                </div>

                <div class="form-group">
                  <label>Valor:</label>
                  <input
                    type="number"
                    placeholder="Valor a pagar:"
                    class="form-control"
                    v-model="cuentaSeleccionada.valor"
                  />
                </div>

                <input
                  class="btn btn-secondary"
                  type="button"
                  value="Actualizar"
                  v-on:click="actualizar()"
                />
              </template>
              <template v-else>
                <div class="form-group">
                  <label for="">Empresa:</label>
                  <select class="form-control" v-model="nuevaCuenta.empresa">
                    <option value="" selected>Empresa</option>
                    <option
                      v-for="(empresa, index) of empresas"
                      :key="index"
                      :value="empresa"
                    >
                      {{ empresa.nombre }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="">Fecha de emisión:</label>
                  <input
                    type="date"
                    placeholder="Fecha de emisión:"
                    class="form-control"
                    v-model="nuevaCuenta.fecha"
                  />
                </div>
                <div class="form-group">
                  <label for="">Concepto:</label>
                  <textarea
                    placeholder="Concepto:"
                    class="form-control"
                    rows="5"
                    v-model="nuevaCuenta.concepto"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label for="">Valor:</label>
                  <input
                    type="number"
                    placeholder="Valor a pagar:"
                    class="form-control"
                    v-model="nuevaCuenta.valor"
                  />
                </div>

                <input
                  class="btn btn-secondary"
                  type="button"
                  value="Agregar"
                  v-on:click="agregar()"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.modal-container {
  max-height: calc(100vh - 60px);
  overflow-y: auto;
}

.scroll {
  max-height: 500px;
  overflow-y: scroll;
}

.disabled {
  opacity: 0.6;
}

.close-reg {
  text-align: end;
  font-size: 18px;
  color: grey;
  cursor: pointer;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  line-height: 1.3 !important;
}

.modal-container {
  width: 480px;
  margin: 0 auto;
  padding: 10px 10px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header {
  padding: 0.5rem 1rem;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

a.buttons-mobile-section__bg-whatsapp {
  background-color: #25d366;
}

.button-whatsapp-modal-desktop {
  padding: 4px 10px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
}

a.button-whatsapp-modal-desktop:hover {
  transition: 0.3s;
  background-color: #1ea74f;
}

@media (max-width: 400px) {
  .modal-container {
    width: 350px;
  }
}

@media (min-width: 400px) and (max-width: 500px) {
  .modal-container {
    width: 400px;
  }
}

@media (min-width: 500px) and (max-width: 600px) {
  .modal-container {
    width: 500px;
  }
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>

<template>       
    <div class="text-center">  
       <template v-if="logueado === true">
            <router-link to="/" class="btn btn-link text-dark">Home</router-link>
            <router-link to="/organizadores" class="btn btn-link text-dark">Organizadores</router-link>
       </template>      
    </div>
</template>

<script>
import Modal from "../Modal_registro";
import {mapState} from "vuex";

export default {
  components: {
    Modal
  },
  props:{
    theme:{
      type: String,
      default: 'default'
    }
  },
  data(){
    return{
      showModal: false
    }
  },
  computed: {
    ...mapState(['logueado', 'usuario', 'admin']),
  },
  methods :{
    cerrarSesion(){
      this.$store.commit('cerrarSesionUsuarioPorTokenVencido');
    }
  },

}
</script>

<style>

</style>
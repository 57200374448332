<template>
  <div>
        <Header></Header> 

        <div class="container">
            <div class="row mb-3">
                <div class="col-sm-12 text-center">         
                    <button type="button"  v-on:click="abrirModalAgregarOrganizador()" class="btn btn-link text-dark mr-2"><i class="fas fa-plus"></i> Agregar organizador</button>         
                </div>
            </div>
            <div class="row">            
                <div class="col-md-6 offset-md-3">
                  <template v-if="cargando">
                      <div class="col-sm-12 text-center">
                        <h5>Cargando...</h5>
                      </div>
                  </template>
                  <template v-else>
                    <template v-if="organizadores.length > 0">
                        <div class="list-group mb-5">
                            <div v-for="(organizador, index) of organizadores" :key="index" class="list-group-item text-dark d-flex justify-content-between align-items-center">
                                <div>
                                  <p class="card-text mb-0"><strong>Nombre:</strong> {{ organizador.nombre }}</p>   
                                  <p class="card-text mb-0"><strong>Cuenta Instagram:</strong> {{ organizador.cuentaIG }}</p>         
                                  <p class="card-text mb-0"><strong>Usuario:</strong> {{ organizador.usuario }}</p>                     
                                </div>
                                <a href="#" class="text-dark" v-on:click="eliminar(organizador)">Eliminar</a>
                            </div>          
                        </div>
                    </template>
                    <template v-else>
                      <div class="col-sm-12 text-center">
                        <h5>Sin resultados</h5>
                      </div>
                    </template>
                  </template>
                </div>
            </div>        
        </div>  

        <AgregarOrganizador :show="showModalOrganizador" @close="showModalOrganizador = false"></AgregarOrganizador>
       
  </div>   
</template>

<script>

import firebase from "firebase/compat/app";

// Modales
import AgregarOrganizador from "../../components/modales/AgregarOrganizador";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
  components: {
      AgregarOrganizador
  },
  data() {
    return {
      showModalOrganizador: false
    };
  },

   async created() {
    let usuario = localStorage.getItem("usuario");

    if (!usuario) {
      this.$store.commit("eliminarSesion");
      return;
    }

    try {
      const user = firebase.auth().currentUser;

      if (user) {
        this.$store.commit("usuarioActual", user);
        this.user = user;

        this.obtenerDatosUsuario(this.user.phoneNumber);

        this.verificarSiEsAdmin();
        this.fetchOrganizadores();       

      } 
      else {
        this.$store.commit("eliminarSesion");
      }

    } catch (error) {
      console.log("Error al verificar autenticación:", error);
      this.$store.commit("eliminarSesion");
    }
  },

  computed: {
    ...mapState(['logueado', 'usuario', 'admin', 'datosUsuario']),
    ...mapState('organizadores', ['cargando', 'organizadores']),
    ...mapGetters('organizadores', ['getOrganizadores']), 
    organizadores(){
      return this.getOrganizadores;
    },
  },
  methods: {
    ...mapActions(['verificarAdmin', 'obtenerDatosUsuario']),
    ...mapActions('organizadores', ['fetchOrganizadores', 'eliminarOrganizador']), 
    abrirModalAgregarOrganizador() {      
       this.showModalOrganizador = true; 
    },

     async verificarSiEsAdmin() {

      let logueado = firebase.auth().currentUser;
      if (logueado) {
        let usuario = firebase.auth().currentUser.phoneNumber
        await this.verificarAdmin(usuario);
      }

    },
    
    async eliminar(organizador){
        try {   
          await this.eliminarOrganizador(organizador); 
          this.$toastr.s("Organizador eliminado");             
          this.fetchOrganizadores();                  
        } catch (error) {
          console.error(error);
        }
    },
  }

}
</script>

<style>

</style>
import axios from 'axios';

axios.defaults.baseURL = 'https://us-central1-ctips-e397d.cloudfunctions.net/ctips';

export default {
    namespaced: true,
    state: {
        eventos: [],
        eventoSeleccionado: null,
        tipoEvento: null,
        editandoEvento: false,
        organizador: null,
        categoriasEventos: [],
        cargando: true        
    },
    mutations: {
        SET_EVENTOS(state, eventos){
          state.eventos = eventos;
        },
        SET_CARGANDO(state, valor){
          state.cargando = valor;
        },    
        SET_TIPO_EVENTO(state, tipo){
          state.tipoEvento = tipo;
        },    
        SET_EDITANDO_EVENTO(state, valor){
          state.editandoEvento = valor;
        }, 
        SET_EVENTO_SELECCIONADO(state, evento){
          state.eventoSeleccionado = evento;
        },
        SET_ORGANIZADOR(state, organizador){
          state.organizador = organizador;
        },
        SET_CATEGORIAS_EVENTOS(state, categorias){
          state.categoriasEventos = categorias;
        }
    },
    actions: {       

        fetchEventos({commit}){
            axios.get('/listar-eventos')
            .then(response => {
               commit('SET_EVENTOS', response.data.eventos);
               commit('SET_CARGANDO', false);
            })
            .catch(error => {
               console.error("Error", error);
            });
        },

        fetchCategoriasEventos({ commit }){
            axios.get('/listar-categorias-evento')
            .then(response => {
                commit('SET_CATEGORIAS_EVENTOS', response.data.categorias);
            })
            .catch(error => {
                console.error("Error", error);
            });
        },
    
        fetchEventosPorTipo({commit}, tipo){
            axios.post('/listar-eventos-por-tipo', {tipo})
            .then(response => {
                commit('SET_EVENTOS', response.data.eventos);
            })
            .catch(error => {
                console.error("Error", error);
            });
        },

        asignarEventoSeleccionado({ commit }, evento){
            commit("SET_EVENTO_SELECCIONADO", evento); 
        },
        
        asignarTipoEvento({ commit }, tipo){
            commit("SET_TIPO_EVENTO", tipo); 
        },
    
        asignarEditandoEvento({ commit }, valor){
            commit("SET_EDITANDO_EVENTO", valor);
        },
    
        agregarEvento({ commit }, evento) {
            return new Promise((resolve, reject) => {
              axios.post('/crear-evento', evento)
                .then(response => {
                   resolve('ok');
                })
                .catch(error => {
                   reject(error);
                });
            });
        },
    
        actualizarEvento({ commit }, eventoActualizado) {
            return new Promise((resolve, reject) => {
              axios.put('/actualizar-evento', eventoActualizado)
                .then(response => {
                   resolve('ok');
                })
                .catch(error => {
                   reject(error);
                });
            });
        },
        
        eliminarEvento({ commit }, evento) {
            return new Promise((resolve, reject) => {
              axios.delete('/eliminar-evento/' + evento.id)
                .then(response => {
                  resolve('ok');
                })
                .catch(error => {
                  reject(error);
                });
            });
        },
        
        verificarOrganizador({ commit }, usuario){             
          axios.post('/validar-organizador', { usuario })
          .then(response => {
              commit('SET_ORGANIZADOR', response.data.info);         
           })
          .catch(error => {
              console.log("Error: ", error.response.data.mensaje);
          });

        },
    },
    getters: {
        getEventos(state){
            return state.eventos;
        } 
    }

}

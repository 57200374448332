<template>
  <div>
    <Header></Header>
    <div class="container">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <h4 class="mb-3 text-center">Clientes ({{ empresas.length }})</h4>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-12 text-center">
          <button
            type="button"
            v-on:click="abrirModalAgregarEmpresa()"
            class="btn btn-link text-dark mr-2"
          >
            <i class="fas fa-plus"></i> Agregar cliente
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <template v-if="cargando">
            <div class="col-sm-12 text-center">
              <h5>Cargando...</h5>
            </div>
          </template>
          <template v-else>
            <template v-if="empresas.length > 0">
              <div class="list-group mb-5">
                <div
                  v-for="(empresa, index) of empresas"
                  :key="index"
                  class="list-group-item text-dark"
                >
                  <div>
                    <p class="card-text mb-0">
                      <strong>Nombre:</strong> {{ empresa.nombre }}
                    </p>
                    <p class="card-text mb-0">
                      <strong>Dirección:</strong> {{ empresa.direccion }}
                    </p>
                    <p class="card-text mb-0">
                      <strong>NIT:</strong> {{ empresa.nit }}
                    </p>
                    <p class="card-text mb-0">
                      <strong>Teléfono:</strong> {{ empresa.telefono }}
                    </p>
                  </div>
                  <div class="mt-2">
                    <a
                      href="javascript:void(0)"
                      class="d-block-inline text-danger mr-2"
                      v-on:click="abrirModalEditarEmpresa(empresa)"
                      >Editar</a
                    >
                    <a
                      href="javascript:void(0)"
                      class="d-block-inline text-danger"
                      v-on:click="eliminar(empresa)"
                      >Eliminar</a
                    >
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="col-sm-12 text-center">
                <h5>Sin resultados</h5>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>

    <Footer></Footer>

    <AgregarEmpresa
      :show="showModalEmpresa"
      @close="showModalEmpresa = false"
    ></AgregarEmpresa>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";

// Modales
import AgregarEmpresa from "@/components/modales/AgregarEmpresa";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    AgregarEmpresa,
  },
  data() {
    return {
      showModalEmpresa: false,
    };
  },
  async created() {
    let usuario = localStorage.getItem("usuario");

    if (!usuario) {
      this.$store.commit("eliminarSesion");
      return;
    }

    try {
      const user = firebase.auth().currentUser;

      if (user) {
        this.$store.commit("usuarioActual", user);
        this.user = user;

        this.verificarSiEsAdmin();
        this.obtenerDatosUsuario(this.user.phoneNumber);
        
        this.fetchEmpresas();

      } 
      else {
        this.$store.commit("eliminarSesion");
      }
    } catch (error) {
      console.log("Error al verificar autenticación:", error);
      this.$store.commit("eliminarSesion");
    }
  },
  computed: {
    ...mapState(["logueado", "usuario", "admin", "datosUsuario"]),
    ...mapState("empresas", [
      "cargando",
      "empresas",
      "editandoEmpresa",
      "empresaSeleccionada",
    ]),
    ...mapGetters("empresas", ["getEmpresas"]),
    empresas() {
      return this.getEmpresas;
    },
  },
  methods: {
    ...mapActions(["verificarAdmin", "obtenerDatosUsuario"]),
    ...mapActions("empresas", [
      "fetchEmpresas",
      "eliminarEmpresa",
      "asignarEmpresaSeleccionada",
      "asignarEditandoEmpresa",
    ]),

    abrirModalAgregarEmpresa() {
      this.showModalEmpresa = true;
      this.asignarEditandoEmpresa(false);
    },

    abrirModalEditarEmpresa(empresa) {
      this.showModalEmpresa = true;
      this.asignarEditandoEmpresa(true);
      const empresaCopia = { ...empresa };
      this.asignarEmpresaSeleccionada(empresaCopia);
    },

    async verificarSiEsAdmin() {
      let logueado = firebase.auth().currentUser;
      if (logueado) {
        let usuario = firebase.auth().currentUser.phoneNumber;
        await this.verificarAdmin(usuario);
      }
    },

    async eliminar(empresa) {
      try {
        await this.eliminarEmpresa(empresa);
        this.$toastr.s("Empresa eliminada");
        this.fetchEmpresas();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style></style>
